import React from 'react';

import { ConfirmationCodeModal } from '../components/ConfirmationCodeModal/ConfirmationCodeModal';
import { MembershipModal } from '../components/MembershipModal';
import SignUpForm from '../components/SignUp/SignUpForm';
import ForgotPassword from '../containers/ForgotPassword';
import LoginModal from '../containers/Login';
import { useIsDocumentReady } from '../hooks/useIsDocumentReady';
import { useModalByName } from '../hooks/useModalByName';
import { getUserInfo } from '../utils/localStorage.utils';

import {
  EditCardModal,
  EditNameModal,
  EditEmailModal,
  PaymentHistoryModal
} from './AccountSettings';
import { ChangePasswordModal } from './AccountSettings/ChangePasswordModal';
import { EditPhoneModal } from './AccountSettings/EditPhoneModal/EditPhoneModal';
import { AutoOfferModals } from './AutoOfferModals';
import { ConfirmationCodeInputModal } from './ConfirmationCode';
import { ConfirmationCodePhoneInputModal } from './ConfirmationCode/ConfirmationCodePhoneInputModal';
import { HomeownerModals } from './HomeownerModals/HomeownerModals';
import { MarketManagementModals } from './MarketManagementModals/MarketManagementModals';
import { MembershipModals } from './MembershipModals/MembershipModals';
import { EdgePromoModal } from './PromotionalModals/EdgePromoModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import { SmsOptInModal } from './SmsOptInModal/SmsOptInModal';

export const Modals: React.FC<unknown> = () => {
  const isDocumentReady = useIsDocumentReady();

  const [isSignUpModalShowing, , closeSignUpModal] = useModalByName('SignUp');
  const [isLoginModalShowing, , closeLoginModal] = useModalByName('LoginModal');
  const [isForgotPasswordModalShowing, , closeForgotPasswordModal] =
    useModalByName('ForgotPassword');

  if (!isDocumentReady) {
    return null;
  }

  const user = getUserInfo();

  return (
    <>
      <SignUpForm close={closeSignUpModal} isOpen={isSignUpModalShowing} />
      <LoginModal close={closeLoginModal} isOpen={isLoginModalShowing} />
      <ForgotPassword close={closeForgotPasswordModal} isOpen={isForgotPasswordModalShowing} />
      <ConfirmationCodeModal />
      <ResetPasswordModal />
      <EditNameModal />
      <EditEmailModal />
      <EditPhoneModal />
      <EditCardModal />
      <PaymentHistoryModal />
      <ConfirmationCodeInputModal />
      <ConfirmationCodePhoneInputModal />
      <MembershipModal />
      <AutoOfferModals />
      {user && <MarketManagementModals />}
      <MembershipModals />
      <EdgePromoModal />
      <SmsOptInModal />
      <HomeownerModals />
      <ChangePasswordModal />
    </>
  );
};
