import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import thankYouIcon from '../../../public/images/thankyou-image.png';
import { useModalByName } from '../../hooks/useModalByName';
import { SubmitButton } from '../SubmitButton';

import styles from './ResetPassword.module.scss';

export const testIds = {
  root: 'SuccessComponent',
  title: 'title',
  thankYouImage: 'thankYouImage',
  backToLoginButton: 'backToLoginButton'
};

interface SuccessComponentProps {
  loginUrl?: string;
}

export const SuccessComponent = ({ loginUrl }: SuccessComponentProps) => {
  const router = useRouter();
  const [, showLoginModal] = useModalByName('LoginModal');

  return (
    <div
      className={classNames(styles.formContent, styles.successContainer)}
      data-testid={testIds.root}
    >
      <div className={styles.mainHeader}>
        <img
          className="mb-5"
          width="50%"
          src={thankYouIcon.src}
          alt=""
          data-testid={testIds.thankYouImage}
        />
        <div className={styles.title} data-testid={testIds.title}>
          Your password has been reset successfully.
        </div>
      </div>
      <SubmitButton
        onClick={() => {
          if (loginUrl) {
            router.push(loginUrl);
          } else {
            showLoginModal();
          }
        }}
        className=" mt-4"
        data-testid={testIds.backToLoginButton}
      >
        Back to Login
      </SubmitButton>
    </div>
  );
};
