import classNames from 'classnames';
import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Col, Row, Tooltip } from 'reactstrap';

import { emailPattern } from '../../../utils/common.utils';
import { FormField } from '../LoginForm';

interface LoginInputProps {
  register: UseFormRegister<FormField>;
  errors: FieldErrors<FormField>;
}

export const LoginInput = ({ register, errors }: LoginInputProps) => {
  return (
    <Row>
      <Col md={12}>
        <input
          id="email"
          placeholder="Email"
          type="email"
          className={classNames('inputField', {
            inputError: errors.email && errors.email.type === 'required'
          })}
          {...register('email', {
            required: true,
            pattern: emailPattern
          })}
        />
        <Tooltip
          className="mttooltip"
          placement="bottom"
          isOpen={
            errors.email && (errors.email.type === 'required' || errors.email.type === 'pattern')
          }
          target="email"
        >
          {errors?.email?.type === 'pattern' ? 'Enter valid email' : 'Enter your email'}
        </Tooltip>
      </Col>
      <Col md={12}>
        <input
          id="password"
          placeholder="Password"
          type="password"
          className={classNames('inputField', {
            inputError: errors.password && errors.password.type === 'required'
          })}
          {...register('password', { required: true })}
        />
        <Tooltip
          className="mttooltip"
          placement="bottom"
          isOpen={errors.password && errors.password.type === 'required'}
          target="password"
        >
          Enter your password
        </Tooltip>
      </Col>
    </Row>
  );
};
