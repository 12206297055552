import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { EDIT_CARD_MODAL_NAME } from '../';
import { BillingDetails } from '../../../api/_base/generated/data-contracts';
import { beginPayment, getBillingDetails, updateBillingDetails } from '../../../api/subscription';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { AlertBox } from '../../../components/AlertBox';
import { Modal } from '../../../components/Modal';
import { Button, Spinner } from '../../../components/design-system';
import { useModalByName } from '../../../hooks/useModalByName';
import { ModalHeader } from '../../ModalHeader';

import { BillingAddressInputGroup } from './BillingAddressInputGroup';
import styles from './EditCardModal.module.scss';
import { NameInputGroup } from './NameInputGroup';
import { PaymentInformationInputGroup } from './PaymentInformationInputGroup';

export type MembershipUser = {
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
};

export const EditCardModal = () => {
  const [isOpen, , close] = useModalByName(EDIT_CARD_MODAL_NAME);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveDisabledByCard, setSaveDisabledByCard] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [billingDetails, setBillingDetails] = useState<BillingDetails>();
  const [membershipUser, setMembershipUser] = useState<MembershipUser>({});
  const [initialMembershipUser, setInitialMembershipUser] = useState<MembershipUser>({});
  useEffect(() => {
    if (!isOpen) return;
    setLoading(true);
    setErrorMessage(undefined);
    getBillingDetails().then(({ data: { data } }) => {
      setBillingDetails(data);
      const newMembershipUser = {
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        address1: data.address?.address1 || '',
        address2: data.address?.address2 || '',
        city: data.address?.city || '',
        state: data.address?.state || '',
        postalCode: data.address?.postalCode || ''
      };
      setMembershipUser(newMembershipUser);
      setInitialMembershipUser(newMembershipUser);
      setLoading(false);
    });
  }, [isOpen]);

  const onChange = (name: string, value: string) => {
    setMembershipUser({
      ...membershipUser,
      [name]: value || null
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const stripe = typeof jest === 'undefined' ? useStripe() : null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const elements = typeof jest === 'undefined' ? useElements() : null;

  const handleSaveClick = async () => {
    setSaving(true);

    if (cardComplete) {
      if (!elements || !stripe) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        return;
      }

      try {
        const {
          data: {
            data: { clientSecret }
          }
        } = await beginPayment();
        const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: `${membershipUser.firstName} ${membershipUser.lastName}`,
              address: {
                line1: membershipUser.address1,
                line2: membershipUser.address2,
                city: membershipUser.city,
                state: membershipUser.state,
                postal_code: membershipUser.postalCode
              }
            }
          }
        });

        if (error) {
          setErrorMessage(error.message);
          setSaving(false);

          return;
        }

        await updateBillingDetails({
          ...membershipUser,
          // TODO: should not cast here.
          paymentMethodId: setupIntent?.payment_method as string
        });
        close();
        setSaving(false);
      } catch (error: unknown) {
        if (isApiErrorWithMessage(error)) {
          setErrorMessage(getMessageFromError(error));
        } else {
          setErrorMessage('Payment Unsuccessful');
        }

        setSaving(false);
      }
    } else {
      await updateBillingDetails(membershipUser);
      close();
      setSaving(false);
    }
  };

  const saveDisabled =
    saveDisabledByCard ||
    (isEqual(membershipUser, initialMembershipUser) && !cardComplete) ||
    !(
      membershipUser.firstName &&
      membershipUser.lastName &&
      membershipUser.address1 &&
      membershipUser.city &&
      membershipUser.state &&
      membershipUser.postalCode
    );

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={styles.modal}>
      <ModalHeader
        closeIconClassNames={styles.closeIcon}
        onClickIcon={close}
        headerText="Payment Information"
      />
      <div className={styles.modalBody}>
        {loading ? (
          <Spinner className="text-center align-self-center" />
        ) : (
          <>
            {errorMessage && <AlertBox>{errorMessage}</AlertBox>}
            <NameInputGroup
              firstName={membershipUser.firstName}
              lastName={membershipUser.lastName}
              onChange={onChange}
            />
            <BillingAddressInputGroup
              address1={membershipUser.address1}
              address2={membershipUser.address2}
              city={membershipUser.city}
              state={membershipUser.state}
              postalCode={membershipUser.postalCode}
              onChange={onChange}
            />
            <PaymentInformationInputGroup
              setSaveDisabled={setSaveDisabledByCard}
              setCardComplete={setCardComplete}
              {...billingDetails?.card}
            />
          </>
        )}
      </div>

      <Button
        className={styles.closeButton}
        variant="primary"
        type="button"
        onClick={() => handleSaveClick()}
        disabled={saveDisabled || saving}
      >
        {saving ? <Spinner /> : 'Save Changes'}
      </Button>
    </Modal>
  );
};
