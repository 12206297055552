import { PostHog } from 'posthog-js';

import { PropertyDetailsResponseBody } from '../../api/_base/generated/data-contracts';

import { recordPostHogEvent } from './recordPostHogEvent';

interface RecordUserOfferNotSubmittedParams {
  property: PropertyDetailsResponseBody;
  offerAmount: string;
}

export const recordUserOfferNotSubmittedEventName = 'user_offer_notsubmitted';

export const recordUserOfferNotSubmitted = (
  { offerAmount, property }: RecordUserOfferNotSubmittedParams,
  posthog: PostHog
) => {
  recordPostHogEvent(property, recordUserOfferNotSubmittedEventName, posthog, {
    action: 'amount_added',
    offer_amount: offerAmount,
    closing_date: `${property?.projectedCloseDate}`,
    address: `${property?.streetAddress}, ${property?.city}, ${property?.state}, ${property?.zipCode}`,
    territory_id: `${property?.territoryId}`,
    express_property: `${property?.isExpressProperty}`
  });
};
