import { toast } from 'react-toastify';

import * as authApi from '../api/auth/verifySmsCode';
import * as userApi from '../api/user';
import {
  getMessageFromError,
  isApiErrorWithMessage,
  isGetAuthTokenError,
  isUserNotConfirmedError
} from '../api/user';
import { getAuthToken, getHomeownerAuthToken } from '../api/user/getAuthToken';
import { captureApiException } from '../logging';
import { ModalOptions } from '../store/modals';

interface CommonLoginParams {
  email: string;
  password: string;
  setErrorMessage: (s: string) => void;
  handleAfterLogin: () => Promise<void>;
  handleAfterError?: () => Promise<void>;
}

interface LoginParams extends CommonLoginParams {
  showConfirmationCodeModal: (options?: ModalOptions | undefined) => void;
  confirmationCodeRef: React.MutableRefObject<string>;
  setErrorCode: (s: string) => void;
}

export const attemptLogin = async ({
  email,
  password,
  showConfirmationCodeModal,
  confirmationCodeRef,
  setErrorMessage,
  setErrorCode,
  handleAfterLogin,
  handleAfterError
}: LoginParams) => {
  try {
    await getAuthToken(email, password);
  } catch (error: unknown) {
    if (isUserNotConfirmedError(error)) {
      // close this modal and open another one
      showConfirmationCodeModal({
        onComplete: async () => {
          try {
            await authApi.verifySmsCode({
              email: email,
              confirmationCode: confirmationCodeRef.current,
              password: password
            });
            toast.success('Code confirmed.');
          } catch (error: unknown) {
            if (isApiErrorWithMessage(error)) {
              setErrorMessage(getMessageFromError(error));
            } else {
              setErrorMessage('Invalid verification code provided, please try again.');
            }
          }
        }
      });
    } else if (isGetAuthTokenError(error)) {
      // for all other Sundae errors, show the error code or message
      // but it seems like the message should be shown preferably to the code

      if (error.response && error.response.data) {
        const errObj = error.response.data;

        if (errObj.message) setErrorMessage(errObj.message);
        if (errObj.errorCode) setErrorCode(errObj.errorCode);
      }
    } else if (userApi.isApiValidationError(error)) {
      setErrorCode(error.response.data.error);
    } else {
      captureApiException(error);
      setErrorMessage('An unknown error occurred.');
    }

    if (handleAfterError) {
      handleAfterError();
    }

    return;
  }

  try {
    await handleAfterLogin();
  } catch (error: unknown) {
    if (isApiErrorWithMessage(error)) {
      setErrorMessage(error.response?.data.message);
    }
  }
};

type HomeownerLoginParams = CommonLoginParams;

export const attemptHomeownerLogin = async ({
  email,
  password,
  handleAfterLogin,
  handleAfterError,
  setErrorMessage
}: HomeownerLoginParams) => {
  try {
    await getHomeownerAuthToken(email, password);
    await handleAfterLogin();
  } catch (error: unknown) {
    if (isApiErrorWithMessage(error)) {
      setErrorMessage(error.response?.data.message);
    }

    captureApiException(error);

    if (handleAfterError) {
      handleAfterError();
    }
  }
};
