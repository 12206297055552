import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { HelpIcon } from '../Icons';
import { Popover, PopoverTriggerProps, PopoverTrigger, PopoverContent } from '../design-system';

import styles from './HelpPopover.module.scss';

/**
 * Usage: 
 * 
 * import { HelpPopover, HelpPopoverTrigger, HelpPopoverAnchor, HelpContent } from './HelpPopover';

    export default () => (
        <HelpPopover>
            Content displayed in popover. Content trigger is a question mark icon.
        </HelpPopover>
    );
 */

export type Sizes = 'small' | 'medium';

export type HelpPopoverProps = PopoverTriggerProps & {
  children: ReactNode;
  className?: string;
  size?: Sizes;
};

export const HelpPopover = ({
  size = 'medium',
  children,
  className,
  ...rest
}: HelpPopoverProps) => {
  return (
    <Popover {...rest}>
      <PopoverTrigger
        className={classNames(styles.trigger, styles[`${size}`], className)}
        {...rest}
      >
        <HelpIcon />
      </PopoverTrigger>
      <PopoverContent className={classNames(styles.content)}>{children}</PopoverContent>
    </Popover>
  );
};
