import { AxiosError } from 'axios';
import { usePostHog } from 'posthog-js/react';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getUserInfo } from '../../api/user/getUserInfo';
import { SubmitButton } from '../../components/SubmitButton';
import { attemptLogin } from '../../helpers/signInHelpers';
import { useConfirmationCode, useUserEmail } from '../../hooks/store/misc';
import { useModalByName } from '../../hooks/useModalByName';
import { useMoveToHomeScreen } from '../../hooks/useMoveToHomeScreen';
import { AppDispatch } from '../../store';
import { setReferralInfo } from '../../store/misc';

import { LoginErrorAlertBox } from './components/LoginErrorAlertBox';
import { LoginInput } from './components/LoginInput';

export interface FormField {
  email: string;
  password: string;
}

export type SundaeLoginError = AxiosError<{
  success?: false;
  statusCode?: number;
  message?: string;
  errorCode?: string;
}>;

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormField>();
  const [loading, setLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | undefined>(undefined);
  const [loginErrorCode, setLoginErrorCode] = useState<string | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();

  const [, showConfirmationCodeModal] = useModalByName('ConfirmationCode');

  const { confirmationCode } = useConfirmationCode();
  const confirmationCodeRef = useRef<string>(confirmationCode);
  confirmationCodeRef.current = confirmationCode;

  const [, setUserEmail] = useUserEmail();

  const moveToHomeScreen = useMoveToHomeScreen();

  const posthog = usePostHog();

  const onFormSubmit = async (data: FormField) => {
    setLoading(true);
    setUserEmail(data.email);

    const { email, password } = data;

    const handleAfterLogin = async () => {
      setLoading(false);
      dispatch(setReferralInfo(undefined));
      const userInfo = await getUserInfo();
      posthog.identify(userInfo.id, {
        ...userInfo
      });
      moveToHomeScreen();
    };

    const handleAfterError = async () => {
      setLoading(false);
    };

    await attemptLogin({
      email,
      password,
      showConfirmationCodeModal,
      confirmationCodeRef: confirmationCodeRef,
      setErrorMessage: setLoginErrorMessage,
      setErrorCode: setLoginErrorCode,
      handleAfterLogin,
      handleAfterError
    });
  };

  const isLoginErrorVisible =
    (loginErrorCode && loginErrorCode.length > 0) ||
    (loginErrorMessage && loginErrorMessage.length > 0);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={isLoginErrorVisible ? 'shakeeasy' : ''}>
      <LoginErrorAlertBox loginErrorCode={loginErrorCode} loginErrorMessage={loginErrorMessage} />
      <LoginInput register={register} errors={errors} />
      <SubmitButton disabled={loading} loading={loading} type="submit" className="mt-5">
        Sign In
      </SubmitButton>
    </form>
  );
};

export default LoginForm;
