import React, { useState } from 'react';

import { EDIT_NAME_MODAL_NAME } from '..';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { editProfile } from '../../../api/user-account/accountSettingsApi';
import { AlertBox } from '../../../components/AlertBox';
import { Modal } from '../../../components/Modal';
import { useModalByName } from '../../../hooks/useModalByName';
import { useModalManager } from '../../../hooks/useModalManager';
import { captureException } from '../../../logging';
import { getUserInfo } from '../../../utils/localStorage.utils';
import { CancelSaveButtonGroup } from '../CancelSaveButtonGroup/CancelSaveButtonGroup';
import styles from '../EditModal.module.scss';
import { EditModalHeader } from '../EditModalHeader';

export const nameModalTestIds = {
  firstNameInput: 'firstNameInput',
  lastNameInput: 'lastNameInput',
  alertBox: 'alertBox'
};

const userInfo = getUserInfo();

export const EditNameModal = () => {
  const [isOpen, , close, options] = useModalByName(EDIT_NAME_MODAL_NAME);
  const [firstName, setFirstName] = useState(userInfo?.firstName);
  const [lastName, setLastName] = useState(userInfo?.lastName);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { hideAllModals } = useModalManager();
  const [errorMessage, setErrorMessage] = useState('');

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFirstName(newValue);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLastName(newValue);
  };

  const handleNameSave = () => {
    setIsSubmitting(true);

    editProfile({ firstName, lastName })
      .then(() => {
        setIsSubmitting(false);

        hideAllModals();
        options?.onComplete && options.onComplete();
      })
      .catch((error: unknown) => {
        setIsSubmitting(false);

        captureException(error);

        if (isApiErrorWithMessage(error)) {
          setErrorMessage(getMessageFromError(error));
        }
      });
  };

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={styles.modal}>
      <EditModalHeader onClickIcon={close} headerText="Edit Name" />
      <div className={styles.modalContents}>
        <label className={'visually-hidden'} htmlFor="nameInput">
          Please enter new name
        </label>
        <div className="d-flex mt-3">
          <input
            data-testid={nameModalTestIds.firstNameInput}
            name="firstNameInput"
            className="form-control"
            placeholder="First Name"
            value={firstName}
            onChange={handleFirstNameChange}
            maxLength={40}
          />
          <div className="mx-2" />
          <input
            data-testid={nameModalTestIds.lastNameInput}
            name="lastNameInput"
            className="form-control"
            placeholder="Last Name"
            value={lastName}
            onChange={handleLastNameChange}
            maxLength={80}
          />
        </div>
        {errorMessage && (
          <AlertBox data-testid={nameModalTestIds.alertBox}>{errorMessage}</AlertBox>
        )}
        <CancelSaveButtonGroup
          isSubmitting={isSubmitting}
          isSaveDisabled={isSubmitting || !firstName || !lastName}
          onCancelClick={close}
          onSaveClick={handleNameSave}
        />
      </div>
    </Modal>
  );
};
