import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
  GetUserInfoResponseBody,
  PreviewSubscription
} from '../../api/_base/generated/data-contracts';
import {
  getSubscriptionUpdatePreview,
  updateSubscription,
  beginPayment
} from '../../api/subscription';
import {
  getErrorCodeFromError,
  getMessageFromError,
  isApiErrorWithErrorCode,
  isApiErrorWithMessage
} from '../../api/user';
import { Subscription } from '../../contexts/SubscriptionProvider';
import {
  calculateSavings,
  getTierImageOrText,
  mapStaticSubscriptionToStripeSubscription,
  StaticSubscription
} from '../../helpers/subscriptionHelper';
import { captureException } from '../../logging';
import { recordMembershipPaymentFailed } from '../../services/posthog';
import { formatNumToCurrency } from '../../utils/formatter.utils';
import { getUserInfo } from '../../utils/localStorage.utils';
import { AlertBox } from '../AlertBox';
import { Card } from '../Card';
import { Checkbox } from '../Checkbox';
import { ExitIcon } from '../Icons';
import { MultipleChoice } from '../MultipleChoice';
import { Tag } from '../Tag';
import { Button, Input } from '../design-system';

import styles from './MembershipPayment.module.scss';
import { baseInputStyle } from './stripeStyles';

export const testIds = {
  root: 'MembershipPayment',
  subscriptionCost: 'MembershipPaymentSubscriptionCost',
  billingMethod: 'MembershipPaymentBillingMethod'
};

export type MembershipUser = {
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
};

export type MembershipPaymentProps = React.PropsWithChildren<{
  'data-testid'?: string;
  staticSubscription: StaticSubscription;
  subscriptions: Array<Subscription>;
  onPurchaseMembership?: () => void;
}>;

const billedMonthly = 'Billed Monthly';
const billedAnnually = 'Billed Annually';

export const MembershipPayment: React.FC<MembershipPaymentProps> = ({
  staticSubscription,
  subscriptions,
  onPurchaseMembership
}: MembershipPaymentProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const stripe = typeof jest === 'undefined' ? useStripe() : null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const elements = typeof jest === 'undefined' ? useElements() : null;

  const user: GetUserInfoResponseBody | undefined = getUserInfo();
  const userSubscriptionTierInfo = user?.userSubscriptionTierInfo;
  const userIsBilledYearly = user?.userIsBilledYearly ?? false;
  const isUserOnSameTierAsSelectedTier = userSubscriptionTierInfo?.tier === staticSubscription.tier;

  const [membershipUser, setMembershipUser] = useState<MembershipUser>({});
  const [membershipFrequency, setMembershipFrequency] = useState<string>(
    // We want to default to the opposite of the currently selected membership frequency.
    isUserOnSameTierAsSelectedTier && userIsBilledYearly ? billedMonthly : billedAnnually
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [numberComplete, setNumberComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);
  const [hasAgreedToMembershipTermsAndConditions, setHasAgreedToMembershipTermsAndConditions] =
    useState(false);
  const [pendingCouponCode, setPendingCouponCode] = useState('');
  const [couponCode, setCouponCode] = useState('');

  const [monthlyPreview, setMonthlyPreview] = useState<PreviewSubscription>();
  const [annualPreview, setAnnualPreview] = useState<PreviewSubscription>();

  const posthog = usePostHog();

  const monthlySubscriptionInfo = mapStaticSubscriptionToStripeSubscription(
    subscriptions,
    staticSubscription
  );
  const annualSubscriptionInfo = mapStaticSubscriptionToStripeSubscription(
    subscriptions,
    staticSubscription,
    false
  );

  const selectedPreview = membershipFrequency === billedMonthly ? monthlyPreview : annualPreview;
  const selectedSubscription =
    membershipFrequency === billedMonthly ? monthlySubscriptionInfo : annualSubscriptionInfo;

  const updatePreviews = useMemo(
    () =>
      debounce(
        (
          monthlyPriceId: string | undefined,
          annualPriceId: string | undefined,
          membershipUser: MembershipUser,
          couponCode: string | undefined
        ) => {
          if (!monthlyPriceId || !annualPriceId) {
            setErrorMessage('An error has occurred');
            captureException('Unable to determine monthly and yearly prices');

            return;
          }

          const useMembershipUserParams = !!(
            membershipUser.address1 &&
            membershipUser.city &&
            membershipUser.state &&
            membershipUser.postalCode
          );

          Promise.all([
            getSubscriptionUpdatePreview({
              priceId: monthlyPriceId,
              ...(useMembershipUserParams && membershipUser),
              ...(couponCode && { couponCode })
            }),
            getSubscriptionUpdatePreview({
              priceId: annualPriceId,
              ...(useMembershipUserParams && membershipUser),
              ...(couponCode && { couponCode })
            })
          ])
            .then(([monthlyPreviewResponse, annualPreviewResponse]) => {
              if (monthlyPreviewResponse.data.success)
                setMonthlyPreview(monthlyPreviewResponse.data.data);
              if (annualPreviewResponse.data.success)
                setAnnualPreview(annualPreviewResponse.data.data);
            })
            .catch((e: unknown) => {
              if (isApiErrorWithErrorCode(e) && getErrorCodeFromError(e) === 'invalid_coupon') {
                toast.error('Invalid coupon');
                setCouponCode('');
              } else {
                captureException(e);
              }
            });
        },
        500
      ),
    []
  );

  useEffect(
    () =>
      updatePreviews(
        monthlySubscriptionInfo?.id,
        annualSubscriptionInfo?.id,
        membershipUser,
        couponCode
      ),
    [
      membershipUser,
      annualSubscriptionInfo?.id,
      monthlySubscriptionInfo?.id,
      updatePreviews,
      couponCode
    ]
  );

  if (monthlyPreview?.downgrade && annualPreview?.downgrade) {
    return null;
  }

  if (selectedPreview?.downgrade) {
    return null;
  }

  const discountAmount =
    (selectedPreview && selectedPreview.discount && selectedPreview.discount / 100) || 0;
  const subTotal =
    ((selectedPreview?.subTotal && selectedPreview?.subTotal / 100) || 0) - discountAmount;
  const proratedDeducation =
    subTotal && selectedSubscription?.amount && subTotal - selectedSubscription.amount;
  const salesTaxRate = selectedPreview && selectedPreview.taxRate;
  const salesTaxAmount =
    selectedPreview?.tax && ((selectedPreview?.tax && selectedPreview?.tax / 100) || 0);
  const negativeSalesTaxAmount = salesTaxAmount && salesTaxAmount < 0;
  const totalDue = (selectedPreview?.amountDue && selectedPreview?.amountDue / 100) || 0;

  const complete =
    numberComplete &&
    expiryComplete &&
    cvcComplete &&
    membershipUser.firstName &&
    membershipUser.lastName &&
    membershipUser.address1 &&
    membershipUser.city &&
    membershipUser.state &&
    membershipUser.postalCode &&
    hasAgreedToMembershipTermsAndConditions;

  // If user is on Edge and they are billed annually, we can not let them select tier Edge+ Monthly
  const disableNegativeProrationCase =
    userSubscriptionTierInfo?.tier === 1 && staticSubscription.tier === 2 && userIsBilledYearly;

  const multipleChoiceSettings = [
    {
      name: billedMonthly,
      disabled:
        (isUserOnSameTierAsSelectedTier && !userIsBilledYearly) || disableNegativeProrationCase,
      content: (
        <div className={styles.singleOption}>
          <span>Billed Monthly</span>
          <div>
            <b>{formatNumToCurrency(monthlySubscriptionInfo?.amount)}/mo</b>
          </div>
        </div>
      )
    },
    {
      name: billedAnnually,
      disabled: isUserOnSameTierAsSelectedTier && userIsBilledYearly,
      content: (
        <div className={styles.singleOption}>
          <span>Billed Annually</span>
          <Tag className={styles.tag} variant="green" small>
            Save{' '}
            {formatNumToCurrency(
              calculateSavings(monthlySubscriptionInfo?.amount, annualSubscriptionInfo?.amount)
            )}
            !
          </Tag>
          <div>
            <b>{formatNumToCurrency(annualSubscriptionInfo?.amount)}/yr</b>
          </div>
        </div>
      )
    }
  ];

  const onUpdateMember = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const fieldName = e.target.getAttribute('name') || '';

    setMembershipUser({
      ...membershipUser,
      [fieldName]: value
    });
  };

  const handlePurchaseMembership = async () => {
    setIsLoading(true);

    if (!elements || !stripe) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return;
    }

    try {
      const paymentResponse = await beginPayment();
      const data = paymentResponse.data.data;
      const { setupIntent, error } = await stripe.confirmCardSetup(data.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${membershipUser.firstName} ${membershipUser.lastName}`,
            address: {
              line1: membershipUser.address1,
              line2: membershipUser.address2,
              city: membershipUser.city,
              state: membershipUser.state,
              postal_code: membershipUser.postalCode
            }
          }
        }
      });

      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);

        return;
      }

      const priceId =
        membershipFrequency === billedMonthly
          ? monthlySubscriptionInfo?.id
          : annualSubscriptionInfo?.id;

      if (!priceId || !setupIntent?.payment_method) {
        return false;
      }

      await updateSubscription({
        priceId,
        // TODO need to update data contracts here to accept PaymentMethod type or handle
        // another way so as not to cast to string
        paymentMethodId: setupIntent?.payment_method as string,
        hasAgreedToMembershipTermsAndConditions: true,
        prorationDate: selectedPreview?.prorationDate,
        couponCode,
        ...membershipUser
      });

      setIsLoading(false);
      toast.success('Payment Successful');

      onPurchaseMembership && onPurchaseMembership();
    } catch (error: unknown) {
      setIsLoading(false);

      if (isApiErrorWithMessage(error)) {
        setErrorMessage(getMessageFromError(error));
        recordMembershipPaymentFailed(
          {
            errorMessage: error.message
          },
          posthog
        );
      } else {
        setErrorMessage('Payment Unsuccessful');
        recordMembershipPaymentFailed(
          {
            errorMessage: (error as Partial<StripeError> | undefined)?.message
          },
          posthog
        );
      }

      captureException(error);
    }
  };

  return (
    <div className={classNames(styles.splitPanel)} data-testid={testIds.root}>
      <div>
        {errorMessage && <AlertBox>{errorMessage}</AlertBox>}
        <fieldset className={classNames(styles.tempInputs)}>
          <div className={classNames(styles.inputGroup)}>
            <div className={styles.inputField}>
              <Input onChange={onUpdateMember} placeholder="First Name" name="firstName" />
            </div>
            <div className={styles.inputField}>
              <Input onChange={onUpdateMember} placeholder="Last Name" name="lastName" />
            </div>
          </div>
          <div className={styles.inputField}>
            <Input
              className="fs-exclude"
              onChange={onUpdateMember}
              placeholder="Street Address"
              name="address1"
            />
          </div>
          <div className={styles.inputField}>
            <Input
              className="fs-exclude"
              onChange={onUpdateMember}
              placeholder="Street Address (Optional)"
              name="address2"
            />
          </div>
          <Input onChange={onUpdateMember} placeholder="City" name="city" />
          <div className={classNames(styles.inputGroup)}>
            <div className={styles.inputField}>
              <Input onChange={onUpdateMember} placeholder="State" name="state" />
            </div>
            <div className={styles.inputField}>
              <Input
                onChange={onUpdateMember}
                placeholder="Zipcode"
                name="postalCode"
                type="number"
              />
            </div>
          </div>
        </fieldset>
        <span id="payment-info" className={styles.label}>
          Payment Information
        </span>
        <fieldset className={classNames(styles.tempInputs)} aria-labelledby="payment-info">
          <CardNumberElement
            className={classNames(
              'align-self-stretch',
              styles.stripePaymentInputOverride,
              'fs-exclude'
            )}
            options={{
              style: baseInputStyle
            }}
            onChange={(e) => setNumberComplete(e.complete)}
          />
          <div className={classNames(styles.inputGroup)}>
            <CardExpiryElement
              className={classNames(
                styles.stripePaymentInputOverride,
                styles.inputField,
                'fs-exclude'
              )}
              options={{
                style: baseInputStyle
              }}
              onChange={(e) => setExpiryComplete(e.complete)}
            />
            <CardCvcElement
              className={classNames(
                styles.stripePaymentInputOverride,
                styles.inputField,
                'fs-exclude'
              )}
              options={{
                style: baseInputStyle
              }}
              onChange={(e) => setCvcComplete(e.complete)}
            />
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputField}>
              <Input
                value={pendingCouponCode}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPendingCouponCode(e.target.value.replace(/[^a-z0-9]/gi, ''))
                }
                placeholder="Coupon Code"
                name="couponCode"
              />
            </div>
            <Button
              onClick={() => {
                setCouponCode(pendingCouponCode);
                setPendingCouponCode('');
              }}
              variant="secondary"
              size="small"
            >
              Apply
            </Button>
          </div>
        </fieldset>
      </div>
      <div>
        <Card noPadding>
          <div className={styles.cardSegmentWithBorder}>
            <div className={styles.subscriptionTitle}>
              {getTierImageOrText(staticSubscription.name)}
            </div>
            {staticSubscription?.markets !== -1 && (
              <span>Place offers in {staticSubscription?.markets} markets</span>
            )}
            {staticSubscription?.markets === -1 && <span>Place offers in all markets</span>}
          </div>
          <div className={styles.cardSegmentWithBorder}>
            <span className={styles.label}>Select Billing Method</span>
            <MultipleChoice
              data-testid={testIds.billingMethod}
              firstOptionSelectedByDefault
              options={multipleChoiceSettings}
              selected={membershipFrequency}
              onSelection={(newMembershipFrequency: string) => {
                setMembershipFrequency(newMembershipFrequency);
              }}
            />
            <dl className={styles.infoList}>
              <dt className={styles.label}>Subscription Cost</dt>
              <dd className={styles.value} data-testid={testIds.subscriptionCost}>
                {formatNumToCurrency(selectedSubscription?.amount, 2, 2)}
              </dd>
              {!!proratedDeducation && (
                <>
                  <dt className={styles.label}>Prorated Deduction & Coupons</dt>
                  <dd className={styles.value}>{formatNumToCurrency(proratedDeducation)}</dd>
                </>
              )}
              {!!couponCode && (
                <>
                  <dt className={styles.label}>
                    <Tag variant="gray" className={styles.couponTag}>
                      <span>{couponCode}</span>
                      <ExitIcon
                        onClick={() => {
                          setCouponCode('');
                        }}
                      />
                    </Tag>
                  </dt>
                  <dd className={styles.value}></dd>
                </>
              )}
              <dt className={styles.label}>Subtotal</dt>
              <dd className={styles.value}>{formatNumToCurrency(subTotal, 2, 2)}</dd>
              <dt className={styles.label}>Sales tax ({salesTaxRate}%)</dt>
              <dd className={styles.value}>
                {formatNumToCurrency(salesTaxAmount || 0, 2, 2)}
                {negativeSalesTaxAmount ? '*' : ''}
              </dd>
              {!!negativeSalesTaxAmount && (
                <p className={styles.negativeTax}>*Reflects refund from previous taxes paid</p>
              )}
            </dl>
          </div>
          <div className={styles.cardSegment}>
            <dl className={styles.totalRow}>
              <dt className={styles.label}>Total due today</dt>
              <dd className={styles.value}>{formatNumToCurrency(totalDue, 2, 2)}</dd>
            </dl>
            <p className={styles.content}>
              Your upgrade will be effective as of today. Your card will be automatically charged on
              the same day every {membershipFrequency === billedMonthly ? 'month' : 'year'}. If you
              cancel at any time your membership will be active until the end of the current billing
              cycle.
            </p>
          </div>
        </Card>
      </div>
      <div>
        <fieldset className={classNames(styles.tempInputs)}>
          <Checkbox
            label={
              <>
                By checking this box, your agree to our{' '}
                <a
                  className={styles.labelLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/terms"
                >
                  Terms of Use
                </a>
                &nbsp;and&nbsp;
                <a
                  className={styles.labelLink}
                  target="_blank"
                  href="https://sundae.com/privacy-policy/"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </>
            }
            name="termsAgree"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setHasAgreedToMembershipTermsAndConditions(event.target.checked)
            }
            checked={hasAgreedToMembershipTermsAndConditions}
          />
        </fieldset>
        <Button
          disabled={!complete}
          size="large"
          fullWidth
          onClick={() => handlePurchaseMembership()}
          loading={isLoading}
        >
          Purchase Membership
        </Button>
      </div>
    </div>
  );
};
