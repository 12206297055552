// Do we really need this? Seems we should never send a broken url incorrectly like this instead.
export const getCorrectedUrl = (url: string) => {
  return url.search('http') >= 0 ? url : 'https://' + url;
};

export const getPropertyDetailsLink = (propertyId: string) => {
  return `/feed/${propertyId}`;
};

export const getOfferPath = (propertyId: string) => {
  return `/offer/${propertyId}`;
};

export const getPurchaseDirectOfferPath = (propertyId: string) => {
  return `/offer/${propertyId}?purchaseDirect=true`;
};

export const getExternalInvestorLink = (path?: string) => {
  return `https://sundae.com/${path || ``}`;
};

export const getLoginUrlWithPropertyId = (propertyId: string) => {
  return `/login?next=/feed/${propertyId}`;
};

export const getCurrentRoute = () => {
  return window.location.pathname;
};
