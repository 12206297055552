import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import styles from './MultipleChoice.module.scss';

export const testIds = {
  root: 'MultipleChoice'
};

export type MultipleChoiceSetting = {
  name: string;
  disabled: boolean;
  content: JSX.Element | string;
};

type MultipleChoiceProps = React.PropsWithChildren<{
  'data-testid'?: string;
  selected: string | undefined;
  firstOptionSelectedByDefault?: boolean;
  options: Array<string> | Array<MultipleChoiceSetting>;
  onSelection?: (option: string) => unknown;
}>;

export const MultipleChoice: React.FC<MultipleChoiceProps> = ({
  'data-testid': dataTestId,
  options = [],
  selected = '',
  firstOptionSelectedByDefault = false,
  onSelection
}: MultipleChoiceProps) => {
  return (
    <div className={classNames(styles.multipleChoice)} data-testid={dataTestId || testIds.root}>
      {options.map((opt, index) => {
        // If we're working with a string array, just render the content of the option as that string.
        const option: MultipleChoiceSetting = _.isObject(opt)
          ? opt
          : { name: opt, disabled: false, content: <div>{opt}</div> };

        return (
          <div
            className={classNames(option.disabled ? styles.disabled : styles.cursor)}
            onClick={() => {
              if (_.isFunction(onSelection) && !option.disabled) {
                onSelection(option.name);
              }
            }}
            data-testid={`${testIds.root}-${option.name}`}
            key={option.name}
          >
            <input
              type="radio"
              className={classNames(styles.optionStyle, styles.cursor)}
              disabled={option.disabled}
              onChange={_.noop}
              checked={
                selected ? option.name === selected : firstOptionSelectedByDefault && index === 0
              }
            />
            <div
              className={classNames(
                styles.inlineBlock,
                option.disabled ? styles.disabled : styles.cursor,
                styles.fullWidth
              )}
            >
              {option.content}
            </div>
          </div>
        );
      })}
    </div>
  );
};
