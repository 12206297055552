import _ from 'lodash';
import React, { useState } from 'react';

import { updateUser } from '../../api/user/updateUser';
import buttonStyles from '../../common/buttons.module.scss';
import { Checkbox } from '../../components/Checkbox';
import { ExitIcon } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { getUserInfo, setUserInfo } from '../../utils/localStorage.utils';
import styles from '../PurchaseDirectModal/PurchaseDirectModal.module.scss';

import { PURCHASE_DIRECT_EXPRESS_AUCTION_MODAL_NAME } from '.';

export const testIds = {
  root: 'PurchaseDirectExpressAuctionModal',
  closeButton: 'PurchaseDirectExpressAuctionModalCloseButton'
};

type PurchaseDirectExpressAuctionModalProps = React.PropsWithChildren<{
  'data-testid'?: string;
}>;

export const PurchaseDirectExpressAuctionModal: React.FC<
  PurchaseDirectExpressAuctionModalProps
> = () => {
  const [isOpen, , close] = useModalByName(PURCHASE_DIRECT_EXPRESS_AUCTION_MODAL_NAME);
  const [isDontShowChecked, setIsDontShowChecked] = useState(false);
  const user = getUserInfo();

  const handleClosePurchaseDirectExpressAuctionModal = async () => {
    const hasSeenPurchaseDirectAuctionExpressInfo = isDontShowChecked;

    await updateUser({ hasSeenPurchaseDirectAuctionExpressInfo });
    setUserInfo({
      ...user,
      hasSeenPurchaseDirectAuctionExpressInfo
    });
    close();
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={_.noop}
      modalClassNames={styles.purchaseDirectModal}
      data-testid={testIds.root}
    >
      <ExitIcon
        className={buttonStyles.closeIcon}
        onClick={handleClosePurchaseDirectExpressAuctionModal}
        title="Close"
        data-testid={testIds.closeButton}
      />

      <div className={styles.content}>
        <h2 className={styles.title}>About Purchase Direct & Express or Best Offer</h2>

        <p>
          Purchase Direct with Express opportunities are listed with a fixed price set by the
          seller, have expedited closing windows, and limited information in order to provide faster
          offers to motivated sellers.
        </p>
        <p>
          If you are interested in this property, you can acquire the property at the fixed price by
          selecting ‘Purchase Direct.’ You can also make a best offer for the seller to review.
        </p>
        <p>
          The first investor to confirm their purchase direct will receive a Purchase Sales
          Agreement through email to review and sign via Docusign. Once an investor has confirmed
          their purchase, you will not able to make offers on this opportunity.
        </p>
        <p>
          If you aren’t the first, you can still be a backup investor by confirming. Your IA will
          reach out to you if the property becomes available.
        </p>
        <p>There is no home inspection offered on these types of opportunities.</p>

        <Checkbox
          className={styles.dontShowCheckbox}
          onChange={() => setIsDontShowChecked(!isDontShowChecked)}
          label="Don't show me this again"
        />

        <Button onClick={handleClosePurchaseDirectExpressAuctionModal} fullWidth>
          OK
        </Button>
      </div>
    </Modal>
  );
};
