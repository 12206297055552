import classNames from 'classnames';
import { isUndefined } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import * as api from '../../api/auth/forgotPassword';
import { getErrorCodeFromError, getMessageFromError, isApiErrorWithMessage } from '../../api/user';
import buttonStyles from '../../common/buttons.module.scss';
import commonStyles from '../../common/modal.module.scss';
import { ExitIcon } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { ResetForm } from '../../components/ResetPassword';
import { CognitoInitialFormObject } from '../../components/ResetPassword/ResetForm';
import { useConfirmationCode, useUserEmail } from '../../hooks/store/misc';
import { useModalByName } from '../../hooks/useModalByName';

import styles from './ResetPasswordModal.module.scss';

import { COGNITO_RESET_PASSWORD_MODAL_NAME } from '.';

export const testIds = {
  root: 'ResetPasswordModal',
  closeModalButton: 'closeModalButton'
};

export const ResetPasswordModal = () => {
  const [userEmail] = useUserEmail();
  const { confirmationCode } = useConfirmationCode();

  const [isOpen, , close] = useModalByName(COGNITO_RESET_PASSWORD_MODAL_NAME);
  const [errorMessage, setErrorMessage] = useState<ReactNode>();

  const [resetPasswordInitialData, setResetPasswordInitialData] =
    useState<CognitoInitialFormObject>();

  useEffect(() => {
    if (userEmail) {
      setResetPasswordInitialData({
        email: userEmail,
        code: confirmationCode
      });
    }
  }, [userEmail, confirmationCode]);

  if (isUndefined(resetPasswordInitialData)) {
    return null;
  }

  const onResendCode = async () => {
    if (userEmail) {
      try {
        await api.forgotPassword(userEmail);
        toast.success('Resent confirmation code.');
      } catch (error: unknown) {
        if (isApiErrorWithMessage(error)) {
          if (getErrorCodeFromError(error) === 'auth_platform_error') {
            setErrorMessage(
              <>
                Your account has reached the maximum resends of your email verification code. Try
                again later or contact <a href="mailto:support@sundae.com">support@sundae.com</a>{' '}
                for additional help.
              </>
            );
          } else {
            setErrorMessage(getMessageFromError(error));
          }
        } else {
          setErrorMessage('Could not send code.');
        }
      }
    }
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={close}
      modalClassNames={classNames(commonStyles.publicModal, styles.modal)}
      overlayClassNames={styles.overlay}
      data-testid={testIds.root}
    >
      <ExitIcon
        className={buttonStyles.closeIcon}
        onClick={close}
        title="Close"
        data-testid={testIds.closeModalButton}
      />
      <ResetForm
        resetPasswordInitialData={resetPasswordInitialData}
        onResendCode={onResendCode}
        passwordType={'cognito'}
        errorMessage={errorMessage}
      />
    </Modal>
  );
};
