import classNames from 'classnames';
import React from 'react';

import holidayPromoImage from '../../../public/images/megaphone.svg';
import { GetUserInfoResponseBody } from '../../api/_base/generated/data-contracts';
import { updateUser } from '../../api/user/updateUser';
import modalStyles from '../../common/modal.module.scss';
import { Modal } from '../../components/Modal';
import { ModalCloseButton } from '../../components/Modal/ModalCloseButton';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { useModalManager } from '../../hooks/useModalManager';
import { getUserInfo } from '../../utils/localStorage.utils';
import { TEMPORARY_PROMO_MARKET_MAX } from '../MarketManagementModals/ActiveMarketsModal';
import { CHOOSE_ACTIVE_MARKETS_MODAL_NAME } from '../MarketManagementModals/ChooseActiveMarketsModal';

import styles from './EdgePromoModal.module.scss';

export const EDGE_PROMO_MODAL = 'EdgePromoModal';

export const EdgePromoModal = () => {
  const [isOpen, , close] = useModalByName(EDGE_PROMO_MODAL);
  const { showModal } = useModalManager();

  const user: GetUserInfoResponseBody = getUserInfo();

  const handleSelectOrClose = (isSelect?: boolean) => {
    close();

    if (user?.userShouldConfirmMarkets || isSelect) {
      showModal(CHOOSE_ACTIVE_MARKETS_MODAL_NAME);
    }
  };

  const handleCloseModal = () => handleSelectOrClose();

  const handleDoThisLater = async () => {
    await updateUser({ deferQ1EdgePromoModal: true });
    close();
  };

  return (
    <Modal
      isShowing={isOpen}
      modalClassNames={classNames(modalStyles.modal, modalStyles.center)}
      hide={handleCloseModal}
    >
      {<ModalCloseButton isAbsolute onClick={handleCloseModal} />}
      <img src={holidayPromoImage.src} className={styles.holidayImage} />
      <h2 className={modalStyles.modalHeader}>
        Limited Time: Free Access to AutoOffer and More Markets
      </h2>
      <p className={classNames(modalStyles.text, modalStyles.leftAlign)}>
        You&apos;ve been selected to receive free access to AutoOffer and the ability to place
        offers in up to {TEMPORARY_PROMO_MARKET_MAX} markets. These are top premium features from
        Edge, but you&apos;ll have access to them for free for a limited time.
      </p>
      <p className={classNames(modalStyles.text, modalStyles.leftAlign)}>
        To redeem, all you have to do is set your target market(s) and start placing offers.
      </p>
      <Button size="large" onClick={() => handleSelectOrClose(true)} fullWidth>
        Select Markets
      </Button>
      {!user?.userShouldConfirmMarkets && (
        <div className={styles.skipLink} onClick={handleDoThisLater}>
          I&apos;ll do this later
        </div>
      )}
    </Modal>
  );
};
