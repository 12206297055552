import * as Sentry from '@sentry/nextjs';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getMessageFromError, isApiErrorWithMessage } from '../../api/user';
import { resendConfirmationCode } from '../../api/user/resendConfirmationCode';
import { useUserEmail } from '../../hooks/store/misc';
import { useUserPhone } from '../../hooks/store/misc/useUserPhone';
import { SignUpFormData, SignupProps } from '../../types/onBoarding';
import { AlertBox } from '../AlertBox';
import { Checkbox } from '../Checkbox';
import { ConfirmationCodeInput } from '../ConfirmationCodeInput/ConfirmationCodeInput';
import { SundaeBugIcon } from '../Icons';
import ErrorTooltip from '../SignUp/QuestionSets/ErrorTooltip';
import { SubmitButton } from '../SubmitButton';

import styles from './ConfirmationCodeScreen.module.scss';

const defaultHeaderText = 'We have sent a code to your email address, please enter it below.';

export const testIds = {
  root: 'ConfirmationCodeScreen',
  headerText: 'headerText',
  alertBox: 'alertBox'
};

const SHOW_ERROR_AS_TOOLTIP = false;

type MandatoryFormDataProps = {
  smsOptOut: boolean;
};

type GenericFormDataTypes<T extends MandatoryFormDataProps> = {
  initialFormData?: T;
  updateFormData?: (formData: T) => void;
};

type ConfirmationCodeScreenProps<T extends MandatoryFormDataProps> = GenericFormDataTypes<T> & {
  codeLength?: number;
  headerText?: string;
  onConfirm: () => void | Promise<void>;
  onResendCode?: () => void;
  errorMessage?: string;
  hideLogo?: boolean;
  validationType?: 'phone' | 'email';
};

export function ConfirmationCodeScreen<T extends MandatoryFormDataProps>({
  codeLength = 6,
  headerText = defaultHeaderText,
  onConfirm,
  onResendCode,
  hideLogo = false,
  errorMessage: errorMessageProp,
  validationType = 'email',
  initialFormData,
  updateFormData
}: ConfirmationCodeScreenProps<T>) {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [userEmail] = useUserEmail();
  const { userPhone } = useUserPhone();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(errorMessageProp);
  const [loader, setLoader] = useState(false);
  const [optIn, setOptIn] = useState(!initialFormData?.smsOptOut);

  useEffect(() => {
    setErrorMessage(errorMessageProp);
  }, [errorMessageProp]);

  const validationField = validationType === 'email' ? userEmail : userPhone;

  const handleOnChange = () => {
    setOptIn(!optIn);
    updateFormData && updateFormData({ ...initialFormData, smsOptOut: optIn } as T);
  };

  // TODO: Fix this method once we know which api endpoints we need to hit
  const handleResendConfirmationCode =
    onResendCode ||
    (async () => {
      if (userEmail) {
        try {
          await resendConfirmationCode(userEmail);
          toast.success('Resent verification code.');
        } catch (error: unknown) {
          if (isApiErrorWithMessage(error)) {
            setErrorMessage(getMessageFromError(error));
          } else {
            setErrorMessage('Could not resend verification code.');
          }
        }
      } else {
        Sentry.captureMessage(
          'Email was not defined in ConfirmationCodeScreen#resendConfirmationCode.'
        );
      }
    });

  const confirmSignup = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validationField) {
      setLoader(true);

      try {
        await onConfirm();
      } catch (error: unknown) {
        if (isApiErrorWithMessage(error)) {
          setErrorMessage(getMessageFromError(error));
        } else {
          setErrorMessage('Invalid verification code provided, please try again.');
        }
      }

      setLoader(false);
    } else {
      // eslint-disable-next-line no-console
      console.log('Email was not defined in ConfirmationCodeScreen#confirmSignup.');
      Sentry.captureMessage('Email was not defined in ConfirmationCodeScreen#confirmSignup.');
    }
  };

  return (
    <form
      className={styles.confirmationCodeContents}
      data-testid={testIds.root}
      onSubmit={confirmSignup}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {!hideLogo && <SundaeBugIcon className={styles.sundaeBugIcon} />}
      <div className={styles.headerText} data-testid={testIds.headerText}>
        {headerText}
      </div>
      <ConfirmationCodeInput codeLength={codeLength} disableCallback={setSubmitDisabled} />
      {validationType === 'phone' && (
        <div className={styles.secondaryInput}>
          <Checkbox
            label={
              <>
                I agree to receive promotional messages from Sundae. Msg & data rates may apply. Msg
                frequency varies. View{' '}
                <a target="_blank" rel="noopener noreferrer" href="/terms" className={styles.link}>
                  Terms of Use
                </a>
                ,{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sundae.com/terms-of-service/"
                  className={styles.link}
                >
                  Terms of Service
                </a>
                , and{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sundae.com/privacy-policy/"
                  className={styles.link}
                >
                  Privacy Policy
                </a>
                .
              </>
            }
            onChange={handleOnChange}
            checked={optIn}
          />
        </div>
      )}
      {errorMessage && !SHOW_ERROR_AS_TOOLTIP && (
        <AlertBox data-testid={testIds.alertBox}>{errorMessage}</AlertBox>
      )}

      <SubmitButton loading={loader} disabled={submitDisabled} type="submit">
        Submit
      </SubmitButton>

      {errorMessage && SHOW_ERROR_AS_TOOLTIP && (
        <ErrorTooltip
          isOpen={!!errorMessage}
          target={'numberInput'}
          message={errorMessage}
          placement={'top'}
        />
      )}
      {/* @todo: make this a Button component with link variant styles */}
      <div className={styles.resendCode} onClick={handleResendConfirmationCode}>
        Resend my code
      </div>
    </form>
  );
}
