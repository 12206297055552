import React, { useEffect, useState } from 'react';

import { getSubscription } from '../../api/subscription';
import { getUserInfo } from '../../api/user/getUserInfo';
import {
  getStaticSubscriptionMetaDataByNameWithInterval,
  StaticSubscription,
  SUBSCRIPTION_1_TIER_MEMBERSHIP_NAME,
  SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME
} from '../../helpers/subscriptionHelper';
import { useModalByName } from '../../hooks/useModalByName';

import { ActiveMarketsModal, Q1_EDGE_PROMO_TEXT } from './ActiveMarketsModal';
import { CHOOSE_PRIMARY_MARKETS_MODAL_NAME } from './ChoosePrimaryMarketsModal';

export const CHOOSE_ACTIVE_MARKETS_MODAL_NAME = 'ChooseActiveMarketsModal';

export const ChooseActiveMarketsModal = () => {
  const [isOpen, , close, options] = useModalByName(CHOOSE_ACTIVE_MARKETS_MODAL_NAME);
  const [subscription, setSubscription] = useState<StaticSubscription>();

  const [q1EdgePromoEnabled, setQ1EdgePromoEnabled] = useState(false);

  const loadSubscription = () => {
    getSubscription().then((response) => {
      if (response.data.success) {
        const subscriptionMetadata = getStaticSubscriptionMetaDataByNameWithInterval(
          response.data.data?.tier || ''
        );
        setSubscription(subscriptionMetadata);
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      getUserInfo().then(
        (user) => user.q1EdgePromoEnabled && setQ1EdgePromoEnabled(user.q1EdgePromoEnabled)
      );

      const hasContextSubscription = options?.context;

      if (hasContextSubscription) {
        setSubscription(options.context as StaticSubscription);
      } else {
        loadSubscription();
      }
    }
  }, [isOpen]);

  if (!subscription) {
    return null;
  }

  let bodyText = `Your ${subscription.name} membership limits your offers to ${subscription.markets} primary market.`;

  if (subscription.name === SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME) {
    // edge plus
    bodyText = `As an ${subscription.name} member you have access to all markets nationwide. Select your target markets for a more customized experience.`;
  } else if (subscription.name === SUBSCRIPTION_1_TIER_MEMBERSHIP_NAME) {
    // edge
    bodyText = `As an ${subscription.name} Member select up to ${subscription.markets} target markets to place offers in.`;
  }

  if (q1EdgePromoEnabled && subscription.name !== 'Edge+') {
    bodyText = Q1_EDGE_PROMO_TEXT;
  }

  return (
    <ActiveMarketsModal
      isOpen={isOpen}
      close={close}
      showCloseConfirm
      nextModalName={CHOOSE_PRIMARY_MARKETS_MODAL_NAME}
      onComplete={options?.onComplete}
      headerText="Select your target markets"
      bodyText={bodyText}
      subscription={subscription}
    />
  );
};
