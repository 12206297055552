import React from 'react';

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
type SupportLinkProps = React.PropsWithChildren<
  AnchorProps & {
    'data-testid'?: string;
  }
>;

export const testIds = {
  root: 'SupportLink'
};

const SupportLink: React.FC<SupportLinkProps> = ({
  children,
  'data-testid': dataTestId = testIds.root,
  ...otherProps
}: SupportLinkProps) => {
  // const { children, ...elementProps } = props;

  return (
    <a href="mailto:support@sundae.com" data-testid={dataTestId} {...otherProps}>
      {children ?? 'support@sundae.com'}
    </a>
  );
};

export default SupportLink;
