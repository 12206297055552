import React from 'react';
import { toast } from 'react-toastify';

import { GetUserInfoResponseBody } from '../../api/_base/generated/data-contracts';
import { MEMBERSHIP_MODAL_NAME } from '../../components/MembershipModal';
import {
  canUserUpgrade,
  SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME
} from '../../helpers/subscriptionHelper';
import { useModalByName } from '../../hooks/useModalByName';
import { useModalManager } from '../../hooks/useModalManager';
import { captureException } from '../../logging';
import { getUserInfo } from '../../utils/localStorage.utils';

import { ActiveMarketsModal, Q1_EDGE_PROMO_TEXT } from './ActiveMarketsModal';
import { EDIT_PRIMARY_MARKETS_MODAL_NAME } from './EditPrimaryMarketsModal';

export const EDIT_ACTIVE_MARKETS_MODAL_NAME = 'EditActiveMarketsModal';

export const EditActiveMarketsModal = () => {
  const [isOpen, , close] = useModalByName(EDIT_ACTIVE_MARKETS_MODAL_NAME);
  const { showModal } = useModalManager();

  const user: GetUserInfoResponseBody | undefined = getUserInfo();
  if (!user) return null;
  const { userSubscriptionTierInfo, q1EdgePromoEnabled } = user;

  if (!userSubscriptionTierInfo) {
    toast.error('Subscription not available');
    captureException({ errorMessage: 'Subscription not present', user: user });

    return null;
  }

  const showUpgradeText = canUserUpgrade(userSubscriptionTierInfo.tier);

  const showUpgradeMembership = () => {
    showModal(MEMBERSHIP_MODAL_NAME);
  };

  const upgradeText = (
    <>
      {q1EdgePromoEnabled ? <p>{Q1_EDGE_PROMO_TEXT}</p> : ''}
      Reminder, you may only edit your target markets once per year. For access to all markets
      nationwide{' '}
      <a href="#" onClick={showUpgradeMembership}>
        upgrade to {SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME}
      </a>
      .
    </>
  );

  return (
    <ActiveMarketsModal
      isOpen={isOpen}
      close={close}
      nextModalName={EDIT_PRIMARY_MARKETS_MODAL_NAME}
      headerText="Edit your target markets"
      bodyText={
        showUpgradeText ? (
          upgradeText
        ) : (
          <>
            As an {SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME} member you have access to all markets
            nationwide. Select your target markets for a more customized experience.
          </>
        )
      }
    />
  );
};
