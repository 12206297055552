import classNames from 'classnames';
import React from 'react';

export const testIds = {
  root: 'AlertBox'
};

type AlertBoxProps = React.PropsWithChildren<{
  className?: string;
  noDefaultMargin?: boolean;
  'data-testid'?: string;
}>;

export const AlertBox: React.FC<AlertBoxProps> = ({
  children,
  className,
  noDefaultMargin,
  'data-testid': dataTestId
}) => (
  <div
    className={classNames('alert alert-danger fade show', { 'mt-2': !noDefaultMargin }, className)}
    role="alert"
    data-testid={dataTestId || testIds.root}
  >
    {children}
  </div>
);
