import classNames from 'classnames';
import React from 'react';

import styles from './BackLink.module.scss';
import { BackLinkProps } from './BackLinkTypes';

export const testIds = {
  root: 'BackLink',
  anchor: 'BackLinkAnchor'
};

const BackLink = ({
  label = '',
  slug,
  iconUrl = null,
  icon = null,
  className,
  linkClassName,
  iconClassName,
  onClick
}: BackLinkProps): React.ReactElement => {
  return (
    <div className={classNames(styles.backLink, className)} data-testid={testIds.root}>
      <a href={slug} className={linkClassName} onClick={onClick} data-testid={testIds.anchor}>
        {iconUrl && (
          <img
            src={iconUrl}
            className={classNames(styles.backLinkIcon, iconClassName)}
            alt=""
            role="img"
          />
        )}
        {icon && <>{icon}</>}
        <span>{label}</span>
      </a>
    </div>
  );
};

export default BackLink;
