import Link from 'next/link';
import React from 'react';

import { AlertBox } from '../../../components/AlertBox';
import SupportLink from '../../../components/SupportLink';
import { useModalByName } from '../../../hooks/useModalByName';

export const testIds = {
  alertBox: 'alertBox'
};

interface LoginErrorAlertBoxProps {
  loginErrorCode?: string;
  loginErrorMessage?: string;
}

export const LoginErrorAlertBox = ({
  loginErrorCode,
  loginErrorMessage
}: LoginErrorAlertBoxProps) => {
  const [, showForgotPasswordModal] = useModalByName('ForgotPassword');

  if (!loginErrorCode && !loginErrorMessage) {
    return null;
  }

  return (
    <AlertBox data-testid={testIds.alertBox}>
      {loginErrorCode === 'not_approved' ? (
        <div>
          Account approval for your account is still pending. Please contact <SupportLink /> if you
          have any questions.
        </div>
      ) : loginErrorCode === 'invalid_signup' ? (
        <div>
          This email already exists, if you have forgotten your password please click{' '}
          <Link href="/">
            <a onClick={() => showForgotPasswordModal()}>here </a>
          </Link>{' '}
          to reset your password.
        </div>
      ) : loginErrorCode === 'user_not_exist' ? (
        <div>Email is not registered with us. Please try creating an account</div>
      ) : loginErrorCode === 'partial_account' ? (
        <div>
          {loginErrorMessage && loginErrorMessage} Please contact <SupportLink /> for assistance.
        </div>
      ) : (
        <div>{loginErrorMessage || loginErrorCode}</div>
      )}
    </AlertBox>
  );
};
