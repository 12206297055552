import _ from 'lodash';
import React, { useState } from 'react';

import { updateUser } from '../../api/user/updateUser';
import buttonStyles from '../../common/buttons.module.scss';
import { Checkbox } from '../../components/Checkbox';
import { ExitIcon } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { getUserInfo, setUserInfo } from '../../utils/localStorage.utils';

import styles from './ExpressPropertyModal.module.scss';

import { EXPRESS_PROPERTY_MODAL_NAME } from '.';

export const testIds = {
  root: 'ExpressPropertyModal',
  closeButton: 'ExpressPropertyModalCloseButton'
};

type ExpressPropertyModalProps = React.PropsWithChildren<{
  'data-testid'?: string;
}>;

export const ExpressPropertyModal: React.FC<ExpressPropertyModalProps> = () => {
  const [isOpen, , close] = useModalByName(EXPRESS_PROPERTY_MODAL_NAME);
  const [isDontShowChecked, setIsDontShowChecked] = useState(false);
  const user = getUserInfo();

  const handleCloseExpressPropertyModal = async () => {
    const hasSeenExpressPropertyInfo = isDontShowChecked;

    await updateUser({ hasSeenExpressPropertyInfo });
    setUserInfo({
      ...user,
      hasSeenExpressPropertyInfo
    });
    close();
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={_.noop}
      modalClassNames={styles.expressPropertyModal}
      data-testid={testIds.root}
    >
      <ExitIcon
        className={buttonStyles.closeIcon}
        onClick={handleCloseExpressPropertyModal}
        title="Close"
        data-testid={testIds.closeButton}
      />

      <div className={styles.content}>
        <h2 className={styles.title}>About Express Properties</h2>

        <p>
          Express properties have expedited closing windows and limited information in order to
          provide faster offers to motivated sellers.
        </p>
        <p>
          When you purchase these properties you waive the right to a home inspection and agree to
          close by the escrow date listed on the opportunity.
        </p>

        <Checkbox
          className={styles.dontShowCheckbox}
          onChange={() => setIsDontShowChecked(!isDontShowChecked)}
          label="Don't show me this again"
        />

        <Button onClick={handleCloseExpressPropertyModal} fullWidth>
          OK
        </Button>
      </div>
    </Modal>
  );
};
