import classNames from 'classnames';
import React, { useState } from 'react';

import { GetUserInfoResponseBody } from '../../api/_base/generated/data-contracts';
import { updateUserSmsPreferences } from '../../api/user/updateUserSmsPreferences';
import modalStyles from '../../common/modal.module.scss';
import { Checkbox } from '../../components/Checkbox';
import { Modal } from '../../components/Modal';
import { ModalCloseButton } from '../../components/Modal/ModalCloseButton';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { useModalManager } from '../../hooks/useModalManager';
import { getUserInfo } from '../../utils/localStorage.utils';
import { CHOOSE_ACTIVE_MARKETS_MODAL_NAME } from '../MarketManagementModals/ChooseActiveMarketsModal';
import { EDGE_PROMO_MODAL } from '../PromotionalModals/EdgePromoModal';

import styles from './SmsOptInModal.module.scss';

export const SMS_OPT_IN_MODAL = 'SmsOptInModal';

export const SmsOptInModal = () => {
  const [isOpen, , close] = useModalByName(SMS_OPT_IN_MODAL);
  const { showModal } = useModalManager();
  const [optIn, setOptIn] = useState(false);

  const user: GetUserInfoResponseBody = getUserInfo();

  const handleClose = () => {
    close();

    if (user?.showQ1EdgePromoModal && !user.userHasPaidSubscription) {
      showModal(EDGE_PROMO_MODAL);
    } else if (user?.userShouldConfirmMarkets) {
      showModal(CHOOSE_ACTIVE_MARKETS_MODAL_NAME);
    }
  };

  const handleSignUpOrNotInterested = async (smsOptIn: boolean) => {
    await updateUserSmsPreferences({ smsOptIn });

    handleClose();
  };

  return (
    <Modal
      isShowing={isOpen}
      modalClassNames={classNames(modalStyles.modal, modalStyles.center)}
      hide={handleClose}
    >
      {<ModalCloseButton isAbsolute onClick={handleClose} />}
      <h2 className={modalStyles.modalHeader}>Sign Up For Text Alerts</h2>
      <p className={classNames(modalStyles.text)}>
        Don&apos;t miss out on a great opportunity or important updates about your bid &mdash; sign
        up to receive important text alerts.
      </p>
      <Checkbox
        className={modalStyles.leftAlign}
        label={
          <>
            I agree to receive promotional messages from Sundae. Msg & data rates may apply. Msg
            frequency varies. View{' '}
            <a target="_blank" rel="noopener noreferrer" href="/terms" className={styles.link}>
              Terms of Use
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sundae.com/terms-of-service/"
              className={styles.link}
            >
              Terms of Service
            </a>
            , and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sundae.com/privacy-policy/"
              className={styles.link}
            >
              Privacy Policy
            </a>
            .
          </>
        }
        onChange={(e) => setOptIn(e.target.checked)}
        checked={optIn}
      />
      <Button
        size="large"
        className={styles.signUp}
        onClick={() => handleSignUpOrNotInterested(optIn)}
        fullWidth
        disabled={!optIn}
      >
        Sign Up
      </Button>
      <div className={styles.notInterested} onClick={() => handleSignUpOrNotInterested(false)}>
        I&apos;m not interested
      </div>
    </Modal>
  );
};
