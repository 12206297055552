import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { uniqueId } from 'lodash';
import React from 'react';

import { Button } from '../design-system';

import style from './ConfirmDialog.module.scss';

export const testIds = {
  root: 'ConfirmDialog',
  cancelButton: 'ConfirmDialogCancelButton',
  confirmButton: 'ConfirmDialogConfirmButton'
};

export interface ConfirmDialogProps {
  title: React.ReactNode;
  onCancel?: () => void | Promise<void>;
  onConfirm?: () => void | Promise<void>;
  cancelText?: React.ReactNode;
  confirmText?: React.ReactNode;
  isShowing?: boolean;
  isCentered?: boolean; // @todo: Remove this and clean up with new modal styles. Code smell to center all modals except signup.
  children?: React.ReactNode;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  children,
  title,
  onCancel,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'OK',
  isShowing,
  isCentered = true,
  ...rest
}) => {
  const uid = uniqueId('alert-dialogue');

  return (
    <FocusTrap>
      <div
        className={classNames(style.backdrop, {
          [style.center]: isCentered
        })}
        tabIndex={0}
        data-testid={testIds.root}
      >
        <div
          className={style.confirmDialog}
          role="alertdialog"
          aria-modal="true"
          aria-labelledby={`${uid}-label`}
          aria-describedby={`${uid}-desc`}
          {...rest}
        >
          <h3 className={classNames(style.header)} id={`${uid}-label`}>
            {title}
          </h3>

          <div className={classNames(style.container)}>
            {children && (
              <p className={classNames(style.content)} id={`${uid}-desc`}>
                {children}
              </p>
            )}

            <div className={classNames(style.btnGroup)}>
              {onCancel && (
                <Button
                  variant="primary"
                  type="button"
                  size="large"
                  onClick={onCancel}
                  data-testid={testIds.cancelButton}
                >
                  {cancelText}
                </Button>
              )}
              {onConfirm && (
                <Button
                  variant="secondary"
                  type="button"
                  size="large"
                  onClick={onConfirm}
                  data-testid={testIds.confirmButton}
                >
                  {confirmText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};
