import React, { useState } from 'react';

import { EDIT_EMAIL_MODAL_NAME } from '..';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { updateEmail } from '../../../api/user-account/accountSettingsApi';
import { AlertBox } from '../../../components/AlertBox';
import { Modal } from '../../../components/Modal';
import { useModalByName } from '../../../hooks/useModalByName';
import { useModalManager } from '../../../hooks/useModalManager';
import { captureException } from '../../../logging';
import { emailPattern } from '../../../utils/common.utils';
import { getUserInfo } from '../../../utils/localStorage.utils';
import { CONFIRMATION_CODE_INPUT_MODAL_NAME } from '../../ConfirmationCode';
import { CancelSaveButtonGroup } from '../CancelSaveButtonGroup/CancelSaveButtonGroup';
import styles from '../EditModal.module.scss';
import { EditModalHeader } from '../EditModalHeader';

export const emailModalTestIds = {
  emailInput: 'emailInput',
  alertBox: 'alertBox'
};

const userInfo = getUserInfo();

export const EditEmailModal = () => {
  const [isOpen, , close, options] = useModalByName(EDIT_EMAIL_MODAL_NAME);
  const [email, setEmail] = useState(userInfo?.email);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(!!email && !!email.match(emailPattern));
  const { showModal, hideAllModals } = useModalManager();
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const doesMatchPattern = newValue.match(emailPattern);
    setEmail(newValue);
    setIsEmailValid(!!doesMatchPattern);
  };

  const handleEmailSave = () => {
    setIsSubmitting(true);

    updateEmail(email)
      .then(() => {
        setIsSubmitting(false);

        hideAllModals();
        showModal(CONFIRMATION_CODE_INPUT_MODAL_NAME, { onComplete: options?.onComplete });
      })
      .catch((error: unknown) => {
        setIsSubmitting(false);

        captureException(error);

        if (isApiErrorWithMessage(error)) {
          setErrorMessage(getMessageFromError(error));
        } else {
          setErrorMessage('Invalid verification code provided, please try again.');
        }
      });
  };

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={styles.modal}>
      <EditModalHeader onClickIcon={close} headerText="Edit Email" />
      <div className={styles.modalContents}>
        <label className={'visually-hidden'} htmlFor="emailInput">
          Please enter new email
        </label>
        <input
          data-testid={emailModalTestIds.emailInput}
          name="emailInput"
          className="form-control mt-3"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
        {errorMessage && (
          <AlertBox data-testid={emailModalTestIds.alertBox}>{errorMessage}</AlertBox>
        )}
        <CancelSaveButtonGroup
          isSubmitting={isSubmitting}
          isSaveDisabled={isSubmitting || !isEmailValid}
          onCancelClick={close}
          onSaveClick={handleEmailSave}
        />
      </div>
    </Modal>
  );
};
