import React from 'react';

import { BidIncrementModal } from './BidIncrementModal';
import { LearnMoreModal } from './LearnMoreModal';

export const AutoOfferModals = () => {
  return (
    <>
      <LearnMoreModal />
      <BidIncrementModal />
    </>
  );
};
