import { PostHog } from 'posthog-js';

import { PropertyDetailsResponseBody } from '../../api/_base/generated/data-contracts';

import { recordPostHogEvent } from './recordPostHogEvent';

interface RecordUserPurchaseDirectSubmittedParams {
  property: PropertyDetailsResponseBody;
}

export const recordUserPurchaseDirectSubmittedEventName = 'user_purchase_direct';

export const recordUserPurchaseDirectSubmitted = (
  { property }: RecordUserPurchaseDirectSubmittedParams,
  posthog: PostHog
) => {
  recordPostHogEvent(property, recordUserPurchaseDirectSubmittedEventName, posthog, {
    action: 'submitted',
    closing_date: `${property?.projectedCloseDate}`,
    address: `${property?.streetAddress}, ${property?.city}, ${property?.state}, ${property?.zipCode}`,
    territory_id: `${property?.territoryId}`
  });
};
