import { PostHog } from 'posthog-js';

import { PropertyDetailsResponseBody } from '../../api/_base/generated/data-contracts';

import { recordPostHogEvent } from './recordPostHogEvent';

interface RecordUserOfferSubmittedParams {
  property: PropertyDetailsResponseBody;
  offerAmount: string;
}

export const recordUserOfferSubmittedEventName = 'user_offer';

export const recordUserOfferSubmitted = (
  { property, offerAmount }: RecordUserOfferSubmittedParams,
  posthog: PostHog
) => {
  recordPostHogEvent(property, recordUserOfferSubmittedEventName, posthog, {
    action: 'submitted',
    offer_amount: offerAmount,
    closing_date: `${property?.projectedCloseDate}`,
    address: `${property?.streetAddress}, ${property?.city}, ${property?.state}, ${property?.zipCode}`,
    territory_id: `${property?.territoryId}`,
    express_property: `${property?.isExpressProperty}`
  });
};
