import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'reactstrap';

import thankYouIcon from '../../../public/images/thankyou-image.png';

import styles from './ResetPassword.module.scss';

interface IProps {
  isLinkValid: boolean;
}

export const testIds = {
  root: 'LoadingComponent',
  title: 'title',
  spinner: 'spinner',
  thankYouImage: 'thankYouImage'
};

export const LoadingComponent = (props: IProps) => {
  const { isLinkValid } = props;

  return (
    <div className={styles.formContent} data-testid={testIds.root}>
      <div className={styles.mainHeader}>
        <img
          className="mb-5"
          width="50%"
          src={thankYouIcon.src}
          alt=""
          data-testid={testIds.thankYouImage}
        />
        <div className={styles.title} data-testid={testIds.title}>
          Verifying please wait
        </div>
        <div className={classNames(styles.subTitle, 'mt-3')}>
          {!isLinkValid ? (
            <span className="text-danger">Link is not valid</span>
          ) : (
            <Spinner data-testid={testIds.spinner} />
          )}
        </div>
      </div>
    </div>
  );
};
