import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgPurchaseDirect({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={18} cy={18} r={17.5} fill="#1C51A0" stroke="#1C51A0" />
      <path
        d="M10.157 21.081a.735.735 0 001.057.15l.747-.6v6.613c0 .434.34.79.755.79h10.569c.415 0 .755-.356.755-.79v-6.612l.747.6a.728.728 0 001.057-.15.807.807 0 00-.144-1.105l-7.247-5.784a.732.732 0 00-.913 0l-7.247 5.784a.815.815 0 00-.136 1.104z"
        fill="#F4F7F9"
      />
      <path
        d="M18.12 21.511c-.908-.262-1.2-.533-1.2-.955 0-.485.404-.823 1.08-.823.568 0 .852.24.956.623.048.177.18.31.348.31h.12c.264 0 .452-.288.36-.564a1.665 1.665 0 00-1.184-1.129v-.306c0-.37-.268-.667-.6-.667-.332 0-.6.298-.6.667v.293c-.776.187-1.4.747-1.4 1.604 0 1.027.764 1.538 1.88 1.836 1 .267 1.2.658 1.2 1.071 0 .307-.196.796-1.08.796-.66 0-1-.263-1.132-.636-.06-.173-.196-.298-.36-.298h-.112c-.268 0-.456.303-.356.578.228.618.76.982 1.36 1.125v.297c0 .37.268.667.6.667.332 0 .6-.298.6-.667v-.289c.78-.164 1.4-.666 1.4-1.577 0-1.263-.972-1.694-1.88-1.956z"
        fill="#1C51A0"
      />
      <path
        d="M18 10l.627-1.373L20 8l-1.373-.627L18 6l-.627 1.373L16 8l.687.313.686.314L18 10zM23 14l.627-1.373L25 12l-1.373-.627L23 10l-.627 1.373L21 12l.687.313.686.314L23 14zM13 14l.627-1.373L15 12l-1.373-.627L13 10l-.627 1.373L11 12l.687.313.686.314L13 14z"
        fill="#F4F7F9"
      />
    </svg>
  );
}

export default SvgPurchaseDirect;
