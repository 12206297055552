import _ from 'lodash';
import React, { useState } from 'react';

import { updateUser } from '../../api/user/updateUser';
import buttonStyles from '../../common/buttons.module.scss';
import { Checkbox } from '../../components/Checkbox';
import { ExitIcon } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { getUserInfo, setUserInfo } from '../../utils/localStorage.utils';
import styles from '../PurchaseDirectModal/PurchaseDirectModal.module.scss';

import { PURCHASE_DIRECT_AUCTION_MODAL_NAME } from '.';

export const testIds = {
  root: 'PurchaseDirectAuctionModal',
  closeButton: 'PurchaseDirectAuctionModalCloseButton'
};

type PurchaseDirectAuctionModalProps = React.PropsWithChildren<{
  'data-testid'?: string;
}>;

export const PurchaseDirectAuctionModal: React.FC<PurchaseDirectAuctionModalProps> = () => {
  const [isOpen, , close] = useModalByName(PURCHASE_DIRECT_AUCTION_MODAL_NAME);
  const [isDontShowChecked, setIsDontShowChecked] = useState(false);
  const user = getUserInfo();

  const handleClosePurchaseDirectAuctionModal = async () => {
    const hasSeenPurchaseDirectAuctionInfo = isDontShowChecked;

    await updateUser({ hasSeenPurchaseDirectAuctionInfo });
    setUserInfo({
      ...user,
      hasSeenPurchaseDirectAuctionInfo
    });
    close();
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={_.noop}
      modalClassNames={styles.purchaseDirectModal}
      data-testid={testIds.root}
    >
      <ExitIcon
        className={buttonStyles.closeIcon}
        onClick={handleClosePurchaseDirectAuctionModal}
        title="Close"
        data-testid={testIds.closeButton}
      />

      <div className={styles.content}>
        <h2 className={styles.title}>About Purchase Direct or Best Offer</h2>

        <p>
          Purchase Direct or Best Offer opportunities are listed with a fixed price set by the
          seller and open to offers.
        </p>
        <p>
          If you are interested in this property, you can acquire the property at the fixed price by
          selecting ‘Purchase Direct’, or you can place your best offer for the seller to review.
        </p>
        <p>
          The first investor to confirm purchase direct will receive a Purchase Sales Agreement
          through email to review and sign via Docusign. Once an investor has confirmed their
          purchase, you will not be able to place offers on this opportunity.
        </p>
        <p>
          If you aren’t the first, you can still be a backup investor by selecting ‘Purchase Direct’
          and confirming. Your Investor Advisor will reach out to you if the property becomes
          available.
        </p>

        <Checkbox
          className={styles.dontShowCheckbox}
          onChange={() => setIsDontShowChecked(!isDontShowChecked)}
          label="Don't show me this again"
        />

        <Button onClick={handleClosePurchaseDirectAuctionModal} fullWidth>
          OK
        </Button>
      </div>
    </Modal>
  );
};
