import { CONFIRMATION_CODE_MODAL_NAME } from '../components/ConfirmationCodeModal/ConfirmationCodeModal';
import { MEMBERSHIP_MODAL_NAME } from '../components/MembershipModal';
import {
  EDIT_CARD_MODAL_NAME,
  EDIT_NAME_MODAL_NAME,
  EDIT_EMAIL_MODAL_NAME,
  EDIT_PHONE_MODAL_NAME,
  PAYMENT_HISTORY_MODAL_NAME
} from '../modals/AccountSettings';
import { CHANGE_PASSWORD_MODAL_NAME } from '../modals/AccountSettings/ChangePasswordModal';
import { AUTO_OFFER_MODAL_NAMES } from '../modals/AutoOfferModals';
import {
  CONFIRMATION_CODE_INPUT_MODAL_NAME,
  CONFIRMATION_CODE_PHONE_INPUT_MODAL_NAME
} from '../modals/ConfirmationCode';
import { EXPRESS_PROPERTY_MODAL_NAME } from '../modals/ExpressPropertyModal';
import { HOMEOWNER_MODAL_NAMES } from '../modals/HomeownerModals';
import { MARKET_MANAGEMENT_MODAL_NAMES } from '../modals/MarketManagementModals';
import { MEMBERSHIP_MODAL_NAMES } from '../modals/MembershipModals';
import { EDGE_PROMO_MODAL } from '../modals/PromotionalModals/EdgePromoModal';
import { PURCHASE_DIRECT_AUCTION_MODAL_NAME } from '../modals/PurchaseDirectAuctionModal';
import { PURCHASE_DIRECT_EXPRESS_AUCTION_MODAL_NAME } from '../modals/PurchaseDirectExpressAuctionModal';
import { PURCHASE_DIRECT_EXPRESS_MODAL_NAME } from '../modals/PurchaseDirectExpressModal';
import { PURCHASE_DIRECT_MODAL_NAME } from '../modals/PurchaseDirectModal';
import { COGNITO_RESET_PASSWORD_MODAL_NAME } from '../modals/ResetPasswordModal';
import { SMS_OPT_IN_MODAL } from '../modals/SmsOptInModal/SmsOptInModal';

import { RootState } from './index';

export const MODALS = {
  [COGNITO_RESET_PASSWORD_MODAL_NAME]: COGNITO_RESET_PASSWORD_MODAL_NAME,
  [CONFIRMATION_CODE_MODAL_NAME]: CONFIRMATION_CODE_MODAL_NAME,
  ForgotPassword: 'ForgotPassword',
  LoginModal: 'LoginModal',
  SignUp: 'SignUp',
  [MEMBERSHIP_MODAL_NAME]: MEMBERSHIP_MODAL_NAME,
  [EDIT_NAME_MODAL_NAME]: EDIT_NAME_MODAL_NAME,
  [EDIT_EMAIL_MODAL_NAME]: EDIT_EMAIL_MODAL_NAME,
  [EDIT_PHONE_MODAL_NAME]: EDIT_PHONE_MODAL_NAME,
  [EDIT_CARD_MODAL_NAME]: EDIT_CARD_MODAL_NAME,
  [PAYMENT_HISTORY_MODAL_NAME]: PAYMENT_HISTORY_MODAL_NAME,
  [CONFIRMATION_CODE_INPUT_MODAL_NAME]: CONFIRMATION_CODE_INPUT_MODAL_NAME,
  [CONFIRMATION_CODE_PHONE_INPUT_MODAL_NAME]: CONFIRMATION_CODE_PHONE_INPUT_MODAL_NAME,
  ...AUTO_OFFER_MODAL_NAMES,
  ...MARKET_MANAGEMENT_MODAL_NAMES,
  ...MEMBERSHIP_MODAL_NAMES,
  [PURCHASE_DIRECT_MODAL_NAME]: PURCHASE_DIRECT_MODAL_NAME,
  [EXPRESS_PROPERTY_MODAL_NAME]: EXPRESS_PROPERTY_MODAL_NAME,
  [PURCHASE_DIRECT_EXPRESS_MODAL_NAME]: PURCHASE_DIRECT_EXPRESS_MODAL_NAME,
  [PURCHASE_DIRECT_EXPRESS_AUCTION_MODAL_NAME]: PURCHASE_DIRECT_EXPRESS_AUCTION_MODAL_NAME,
  [PURCHASE_DIRECT_AUCTION_MODAL_NAME]: PURCHASE_DIRECT_AUCTION_MODAL_NAME,
  [EDGE_PROMO_MODAL]: EDGE_PROMO_MODAL,
  [SMS_OPT_IN_MODAL]: SMS_OPT_IN_MODAL,
  [CHANGE_PASSWORD_MODAL_NAME]: CHANGE_PASSWORD_MODAL_NAME,
  ...HOMEOWNER_MODAL_NAMES
};

export type ModalName = keyof typeof MODALS;

export type ModalOptions = {
  onComplete?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  context?: unknown;
};

type ModalState = {
  isShowing: boolean;
  options: ModalOptions | undefined;
};

export type ModalsState = Record<string, ModalState | undefined>;

export const selectModalsState = (rootState: RootState) => rootState.modals;

const initialState: ModalsState = {};

/* action creators */

const SHOW_MODAL = 'SHOW_MODAL' as const;
const HIDE_MODAL = 'HIDE_MODAL' as const;
const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS' as const;

export const showModal = (name: ModalName, options?: ModalOptions) => {
  return {
    type: SHOW_MODAL,
    payload: {
      name: name,
      options: options
    }
  };
};

export const hideModal = (name: ModalName) => {
  return {
    type: HIDE_MODAL,
    payload: {
      name: name
    }
  };
};

export const hideAllModals = () => {
  return {
    type: HIDE_ALL_MODALS
  };
};

type ModalsAction =
  | ReturnType<typeof showModal>
  | ReturnType<typeof hideModal>
  | ReturnType<typeof hideAllModals>;

/* reducer */

const modalsReducer = (state = initialState, action: ModalsAction): ModalsState => {
  let newState: ModalsState;

  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        [action.payload.name]: {
          isShowing: true,
          options: action.payload.options
        }
      };
    case HIDE_MODAL:
      return {
        ...state,
        [action.payload.name]: {
          isShowing: false,
          options: undefined
        }
      };
    case HIDE_ALL_MODALS:
      newState = {};

      Object.keys(state).forEach((key) => {
        newState[key as ModalName] = {
          isShowing: false,
          options: undefined
        };
      });

      return newState;
    default:
      return state;
  }
};

export default modalsReducer;
