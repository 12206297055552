import classNames from 'classnames';
import { uniqueId } from 'lodash';
import React, { InputHTMLAttributes, ElementRef, forwardRef, ReactNode } from 'react';

import { FormHelperText } from '../../../Form/FormHelperText/FormHelperText';
import { InputLabel } from '../../../Form/InputLabel/InputLabel';

import styles from './Input.module.scss';

export const inputTestIds = {
  root: 'Input'
};

const DEFAULT_TAG = 'input';
export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  fullWidth?: boolean;
  error?: boolean;
  descriptor?: ReactNode;
  label?: React.ReactNode;
  size?: 'small' | 'medium';
}

// @todo: Once inputs are finalized create InputField component that wraps label + input. Finalize component API post membership.
export const Input = forwardRef<ElementRef<typeof DEFAULT_TAG>, InputProps>(
  (
    { className, fullWidth = true, error, descriptor, label, size = 'medium', ...inputProps },
    ref
  ) => {
    const uid = uniqueId('input-descriptor');

    return (
      <div>
        {label && (
          <InputLabel htmlFor={inputProps.id} error={error}>
            {label}
          </InputLabel>
        )}
        <input
          data-testid={inputTestIds.root}
          ref={ref}
          aria-labelledby={descriptor ? uid : undefined}
          className={classNames(
            styles.input,
            {
              [styles.fullWidth]: fullWidth,
              [styles.error]: error,
              [styles.small]: size === 'small'
            },
            className
          )}
          {...inputProps}
        />

        {descriptor ? (
          <FormHelperText error={error} topMargin id={uid}>
            {descriptor}
          </FormHelperText>
        ) : null}
      </div>
    );
  }
);

export default Input;
