import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { RenewIcon } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';

import { AutoOfferInfoModalContents } from './AutoOfferInfoModalContents';

export const LEARN_MORE_MODAL_NAME = 'LearnMoreModal';

export const LearnMoreModal = () => {
  const [isOpen, , close, options] = useModalByName(LEARN_MORE_MODAL_NAME);

  const handleClose = () => {
    if (options?.onCancel) {
      options?.onCancel();
    }

    close();
  };

  return (
    <Modal hide={handleClose} isShowing={isOpen} modalClassNames={modalStyles.modal}>
      <div style={{ textAlign: 'center' }}>
        <AutoOfferInfoModalContents close={handleClose} headerText="AutoOffer Explained">
          <div className="sundaeRed">
            <RenewIcon /> AutoOffer
          </div>
          <br />
          <p>
            Set your initial offer, maximum offer, and the increment you would like to increase your
            offer at. Sundae increases your offer on your behalf until your maximum offer is
            reached.
          </p>
          <p>
            Note, that the increment amount is set as shown in the Offer Increment table, but you
            can increase the baseline amount.
          </p>
          <div className={modalStyles.buttonField}>
            <Button variant="primary" size="large" fullWidth onClick={handleClose}>
              Close
            </Button>
          </div>
        </AutoOfferInfoModalContents>
      </div>
    </Modal>
  );
};
