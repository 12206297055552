import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Tooltip } from 'reactstrap';

import { resetPassword } from '../../api/auth/resetPassword';
import { getMessageFromError, isApiErrorWithMessage } from '../../api/user';
import { SundaeBugIcon } from '../../components/Icons';
import { captureException } from '../../logging';
import { PasswordStrength } from '../../types/onBoarding';
import { passwordRules, strengthCheck } from '../../utils/common.utils';
import { AlertBox } from '../AlertBox';
import { ConfirmationCodeInput } from '../ConfirmationCodeInput/ConfirmationCodeInput';
import { PasswordValidationPopOver } from '../PasswordValidationPopOver';
import { SubmitButton } from '../SubmitButton';

import styles from './ResetPassword.module.scss';
import { SuccessComponent } from './SuccessComponent';

export type CognitoResetPasswordRequest = {
  password: string;
  code: string;
  email: string;
};

export type ResetPasswordRequest = CognitoResetPasswordRequest;

interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

export type CognitoInitialFormObject = {
  code: string;
  email: string;
};

interface ResetFormProps {
  resetPasswordInitialData: CognitoInitialFormObject;
  onResendCode?: () => void;
  passwordType?: string;
  errorMessage?: ReactNode;
  customHeader?: boolean;
  loginUrl?: string;
}

export const testIds = {
  root: 'ResetPasswordForm'
};

export const ResetForm = ({
  resetPasswordInitialData,
  passwordType,
  onResendCode,
  errorMessage,
  customHeader,
  loginUrl
}: ResetFormProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch
  } = useForm<ResetPasswordFormData>({ mode: 'onChange' });
  const isCognito = () => passwordType === 'cognito';

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // only disable submit form if in cognito mode.
  const [isFormSubmitDisabled, setIsFormSubmitDisabled] = useState(!!isCognito);
  const [showConfirmationCodeTooltip, setShowConfirmationCodeTooltip] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState<ReactNode>();

  useEffect(() => {
    setFormErrorMessage(errorMessage);
  }, [errorMessage]);

  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>(passwordRules);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const watchField = watch('password', '');

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const validatePasswordStrength = (e: string) => {
    const passwordValue = e;
    setTooltipOpen(true);
    let psdValid = { ...passwordStrength };
    psdValid = strengthCheck(psdValid, passwordValue);
    setPasswordStrength(psdValid);

    if (psdValid.isValid) {
      setValue('password', passwordValue);
      clearErrors('password');
      setTooltipOpen(false);

      return true;
    } else {
      return false;
    }
  };

  const submitFormData = (dataObject: ResetPasswordRequest) => {
    setIsLoading(true);

    resetPassword(dataObject)
      .then((res) => {
        setIsLoading(false);

        if (res.data.success) {
          setIsSuccess(true);
        }
      })
      .catch((error: unknown) => {
        setIsLoading(false);

        captureException(error);
        // eslint-disable-next-line no-console
        console.log(error);
        const message = isApiErrorWithMessage(error)
          ? getMessageFromError(error)
          : 'Not able to reset password';
        setFormErrorMessage(message);
      });
  };

  const onSubmitData = (data: ResetPasswordFormData) => {
    if (isCognito()) {
      const { code, email } = resetPasswordInitialData as CognitoResetPasswordRequest;

      const dataObject: CognitoResetPasswordRequest = {
        password: data.password,
        code,
        email
      };

      submitFormData(dataObject);
    } else {
      throw new Error('Auth0 password reset no longer supported');

      // const { token, email } =
      //   resetPasswordInitialData as ValidateResetPasswordTokenResponseDataWithToken;
      //
      // const dataObject: Auth0ResetPasswordRequest = {
      //   password: data.password,
      //   token,
      //   email
      // };
      //
      // submitFormData(dataObject);
    }
  };

  const onSubmitClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isFormSubmitDisabled) {
      setShowConfirmationCodeTooltip(true);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const confirmationCodeDisableCallback = (disabled: boolean) => {
    setIsFormSubmitDisabled(disabled);

    if (!disabled) {
      setShowConfirmationCodeTooltip(false);
    }
  };

  if (isSuccess) return <SuccessComponent loginUrl={loginUrl} />;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.resetPasswordContents}
      data-testid={testIds.root}
    >
      {!customHeader && (
        <div className="text-center">
          <SundaeBugIcon className={styles.sundaeBugIcon} />
        </div>
      )}
      <div className={styles.formContent}>
        {!customHeader && (
          <div className={styles.mainHeader}>
            <div className={styles.title}>Reset your password</div>
            {!isCognito() && (
              <div className={styles.subTitle}>
                enter a new password for
                <span> {resetPasswordInitialData && resetPasswordInitialData.email}</span>
              </div>
            )}

            {isCognito() && (
              <div className={styles.subTitle}>
                We&apos;ve sent a verification code to either your email or phone number, please
                enter the code and a new password below.
              </div>
            )}
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmitData)}>
          {formErrorMessage && <AlertBox className="mt-4">{formErrorMessage}</AlertBox>}

          {isCognito() && (
            <>
              <div id="confirmationCode">
                <ConfirmationCodeInput
                  codeLength={6}
                  disableCallback={confirmationCodeDisableCallback}
                />
              </div>
              <Tooltip
                id="tooltip-top"
                className="mttooltip"
                placement="bottom"
                target="confirmationCode"
                isOpen={showConfirmationCodeTooltip}
              >
                Please enter a valid verification code
              </Tooltip>
            </>
          )}

          <Controller
            name="password"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <div className={styles.formField}>
                  <input
                    placeholder="New password"
                    id="password"
                    type="password"
                    className={classNames('inputField', {
                      inputError: errors.password && errors.password.type === 'required'
                    })}
                    onChange={onChange}
                  />
                  <PasswordValidationPopOver
                    passwordToolIconClassName={styles.passwordToolIcon}
                    passwordStrength={passwordStrength}
                    tooltipOpen={
                      tooltipOpen || (errors.password && errors.password.type === 'validate')
                    }
                    setTooltip={toggle}
                    id="validationTooltip"
                  />
                </div>
              );
            }}
            rules={{ required: true, validate: (v) => validatePasswordStrength(v) }}
          />
          <Tooltip
            id="tooltip-top"
            className="mttooltip"
            placement="bottom"
            isOpen={errors.password && errors.password.type === 'required'}
            target="password"
          >
            Please enter password
          </Tooltip>
          <input
            id="confirmPassword"
            type="password"
            className={classNames('inputField')}
            placeholder="Confirm password"
            {...register('confirmPassword', {
              validate: (value) => value === watchField || 'Password do not match'
            })}
          />
          <Tooltip
            id="tooltip-top"
            className="mttooltip"
            placement="bottom"
            isOpen={errors.confirmPassword && errors.confirmPassword.type === 'validate'}
            target="confirmPassword"
          >
            Password do not match
          </Tooltip>
          <SubmitButton className="mt-4" loading={isLoading} type="submit" onClick={onSubmitClick}>
            Reset Password
          </SubmitButton>
          {isCognito() && (
            <>
              {/* @todo: make this a Button component with link variant styles */}

              <div className={styles.resendCode} onClick={onResendCode}>
                Resend my code
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};
