import _ from 'lodash';
import React, { useState } from 'react';

import { updateUser } from '../../api/user/updateUser';
import buttonStyles from '../../common/buttons.module.scss';
import { Checkbox } from '../../components/Checkbox';
import { ExitIcon } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { getUserInfo, setUserInfo } from '../../utils/localStorage.utils';
import styles from '../PurchaseDirectModal/PurchaseDirectModal.module.scss';

import { PURCHASE_DIRECT_EXPRESS_MODAL_NAME } from '.';

export const testIds = {
  root: 'PurchaseDirectExpressModal',
  closeButton: 'PurchaseDirectExpressModalCloseButton'
};

type PurchaseDirectExpressModalProps = React.PropsWithChildren<{
  'data-testid'?: string;
}>;

export const PurchaseDirectExpressModal: React.FC<PurchaseDirectExpressModalProps> = () => {
  const [isOpen, , close] = useModalByName(PURCHASE_DIRECT_EXPRESS_MODAL_NAME);
  const [isDontShowChecked, setIsDontShowChecked] = useState(false);
  const user = getUserInfo();

  const handleClosePurchaseDirectExpressModal = async () => {
    const hasSeenPurchaseDirectExpressInfo = isDontShowChecked;

    await updateUser({ hasSeenPurchaseDirectExpressInfo });
    setUserInfo({
      ...user,
      hasSeenPurchaseDirectExpressInfo
    });
    close();
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={_.noop}
      modalClassNames={styles.purchaseDirectModal}
      data-testid={testIds.root}
    >
      <ExitIcon
        className={buttonStyles.closeIcon}
        onClick={handleClosePurchaseDirectExpressModal}
        title="Close"
        data-testid={testIds.closeButton}
      />

      <div className={styles.content}>
        <h2 className={styles.title}>About Purchase Direct & Express</h2>

        <p>
          Purchase Direct with Express opportunities are listed with a fixed price set by the
          seller, have expedited closing windows, and limited information in order to provide faster
          offers to motivated sellers.
        </p>
        <p>
          The first investor to confirm their purchase will receive a Purchase Sales Agreement
          through email to review and sign via Docusign.
        </p>
        <p>
          If you aren’t the first, you can still be a backup investor by confirming. Your IA will
          reach out to you if the property becomes available.
        </p>
        <p>There is no home inspection offered on these types of opportunities.</p>

        <Checkbox
          className={styles.dontShowCheckbox}
          onChange={() => setIsDontShowChecked(!isDontShowChecked)}
          label="Don't show me this again"
        />

        <Button onClick={handleClosePurchaseDirectExpressModal} fullWidth>
          OK
        </Button>
      </div>
    </Modal>
  );
};
