import {
  TooltipProvider as PrimitiveProvider,
  Root as PrimitiveRoot,
  TooltipTrigger as PrimitiveTrigger,
  TooltipContent as PrimitiveContent,
  TooltipProps as PrimitiveTooltipProps,
  TooltipContentProps as PrimitiveTooltipContentProps
} from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import React, { forwardRef, ElementRef, ReactNode, FC } from 'react';

import { Button } from '../Button';

import styles from './Tooltip.module.scss';

/**
 * Usage: 
 * 
 *  import { Tooltip } from './Tooltip';

    export default () => (
        <Tooltip content="Hover content">Tooltip trigger</Tooltip>r>
    );
 */
interface TooltipContentProps
  extends Omit<PrimitiveTooltipContentProps, 'content'>,
    PrimitiveTooltipProps {
  children: ReactNode;
  content: ReactNode;
  className?: string;
  contentClassName?: string;
}

// Expose underlying API for customization
export const Provider = PrimitiveProvider;
export const Root = PrimitiveRoot;
export const Content = PrimitiveContent;

interface TriggerProps {
  className?: string;
  children?: ReactNode | undefined;
}

export const Trigger: FC<TriggerProps> = ({ children, className }) => {
  return (
    <PrimitiveTrigger className={className} asChild>
      {children}
    </PrimitiveTrigger>
  );
};

export const tooltipTestIds = {
  trigger: 'Trigger'
};

export type ContentType = typeof Content;

export const Tooltip = forwardRef<ElementRef<ContentType>, TooltipContentProps>(
  (
    {
      children,
      content,
      className,
      contentClassName,
      defaultOpen,
      open,
      onOpenChange,
      delayDuration,
      ...props
    },
    forwardedRef
  ) => (
    <Provider>
      <Root
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={onOpenChange}
        delayDuration={delayDuration}
      >
        <Trigger>
          <Button
            variant="bare"
            data-testid={tooltipTestIds.trigger}
            className={classNames(styles.trigger, className)}
          >
            {children}
          </Button>
        </Trigger>
        <Content
          className={classNames(styles.content, contentClassName)}
          align="center"
          ref={forwardedRef}
          {...props}
        >
          {content}
        </Content>
      </Root>
    </Provider>
  )
);
