import React from 'react';

import commonStyles from '../../common/modal.module.scss';
import { ExitIcon, SundaeBugIcon } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { useModalByName } from '../../hooks/useModalByName';

import styles from './Login.module.scss';
import LoginForm from './LoginForm';

interface LoginModalProps {
  close: () => void;
  isOpen: boolean;
}

const LoginModal = (props: LoginModalProps) => {
  const [, showForgotPasswordModal] = useModalByName('ForgotPassword');
  const [, showSignUpModal] = useModalByName('SignUp');

  const { close, isOpen } = props;

  return (
    <Modal isShowing={isOpen} hide={close} modalClassNames={styles.loginModal}>
      <ExitIcon onClick={close} className={commonStyles.closeButton} />
      <div className={styles.formContent}>
        <div className="text-center">
          <SundaeBugIcon fontSize={58} className={styles.logo} />
        </div>
        <p className={styles.loginLabel}>Login</p>
        <LoginForm />
        <div className={styles.bottomText}>
          <div onClick={() => showForgotPasswordModal()} className={styles.forgotText}>
            Forgot my password
          </div>
          <div className={styles.createAccText}>
            First time on Sundae? <span onClick={() => showSignUpModal()}> Create an account</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
