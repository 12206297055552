import classNames from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';

import styles from './NumberInput.module.scss';

interface ConfirmationCodeInputProps {
  inputLength: number;
  disableCallback?: (b: boolean) => void; // Will call this method with false when input length is reached
  inputType?: 'number' | 'password'; // number or number password
  inputStyle?: string;
  inputValue: string;
  setInputValue: (s: string) => void;
  placeholder?: string;
  label?: string;
}

export const numberInputTestIds = {
  numberInput: 'numberInput'
};

export const NumberInput = ({
  inputLength,
  disableCallback,
  inputType = 'number',
  inputValue,
  setInputValue,
  inputStyle,
  placeholder,
  label = 'Please input value'
}: ConfirmationCodeInputProps) => {
  const { register, formState } = useForm({
    mode: 'onChange'
  });

  const { errors } = formState;

  const numberInput = register('numberInput', {
    required: true,
    minLength: inputLength,
    maxLength: inputLength,
    validate: {
      positive: (v) => parseInt(v) > 0
    }
  });

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const numberValue = Number(value);
    if (isNaN(numberValue) || numberValue < 0) return;

    if (value.length <= inputLength) {
      setInputValue(value);
      if (disableCallback) disableCallback(true);
    }

    if (disableCallback && value.length >= inputLength) {
      disableCallback(false);
    }
  };

  return (
    <div className={styles.inputCodeSection}>
      <label className={'visually-hidden'} htmlFor="numberInput">
        {label}
      </label>
      <input
        data-testid={numberInputTestIds.numberInput}
        name="numberInput"
        id="numberInput"
        type={inputType}
        className={classNames(styles.codeInput, inputStyle, {
          [styles.error]: errors.numberInput && errors.numberInput?.type === 'minLength'
        })}
        onChange={(e) => {
          numberInput.onChange(e);
          handleNumberInputChange(e);
        }}
        placeholder={placeholder}
        maxLength={inputLength}
        value={inputValue}
      />
    </div>
  );
};
