import React, { useEffect, useState } from 'react';

import { EmptyColumnValue } from '../../../../helpers/constants';
import { useMinBidValidation } from '../../../../hooks/useMinBidValidation';
import { formatCurrencyToNum, formatNumToCurrency } from '../../../../utils/formatter.utils';
import { AlertBox } from '../../../AlertBox';
import { Modal } from '../../../Modal';
import { Button } from '../../../design-system';
import commonOfferStyles from '../../styles.module.scss';

import styles from './BidIncrementSection.module.scss';

export const rangeMinBidMap = [
  { range: '$0 - $149,999', minIncrement: '$500', rangeMax: '$150,000' },
  { range: '$150k - $299,999', minIncrement: '$1,000', rangeMax: '$300,000' },
  { range: '$300k - $449,999', minIncrement: '$1,200', rangeMax: '$450,000' },
  { range: '$450k - $599,999', minIncrement: '$1,500', rangeMax: '$600,000' },
  { range: '$600k - $799,999', minIncrement: '$3,000', rangeMax: '$800,000' },
  { range: '$800k+', minIncrement: '$5,000', rangeMax: '$10,000,000' }
];

export const getMinBidIncrementFromOffer = (price: number | null | undefined) => {
  if (!price) return EmptyColumnValue;

  const bidIncrementBucket = rangeMinBidMap.find(
    (bidIncrement) => formatCurrencyToNum(bidIncrement.rangeMax) > price
  );

  return bidIncrementBucket ? bidIncrementBucket.minIncrement : EmptyColumnValue;
};

interface BidIncrementSectionProps {
  price?: number | null;
  bidIncrement: string;
  setBidIncrement: (bi: string) => void;
}

export const BidIncrementSection = ({
  price,
  bidIncrement,
  setBidIncrement
}: BidIncrementSectionProps) => {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const minBid = getMinBidIncrementFromOffer(price);
  const isBidEmpty = minBid === EmptyColumnValue;

  useEffect(() => {
    if (!isBidEmpty) {
      setBidIncrement(formatCurrencyToNum(minBid).toString());
    }
  }, [minBid, setBidIncrement]);

  const validationObject = useMinBidValidation(bidIncrement, formatCurrencyToNum(minBid));

  useEffect(() => {
    setErrorMessage(validationObject.errorMessage);
  }, [validationObject.errorMessage]);

  const handleBidIncrementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    const valueAsNumber = formatCurrencyToNum(newValue);

    if (valueAsNumber || valueAsNumber === 0) {
      setBidIncrement(valueAsNumber.toString());
    }
  };

  const hideBidModal = () => setIsModalShowing(false);

  const showBidModal = () => {
    if (formatCurrencyToNum(minBid)) {
      setIsModalShowing(true);
    }
  };

  const bidIncrementCurrency = formatNumToCurrency(Number(bidIncrement));
  const bidIncrementDollarValue = isBidEmpty ? bidIncrement : bidIncrementCurrency;

  return (
    <>
      <div className={styles.bidIncrementContainer} onClick={showBidModal}>
        <div>Offer Increment</div>
        <div>
          <span>{bidIncrementDollarValue}</span>
          {!isBidEmpty && <span className={styles.editButton}>edit</span>}
        </div>
      </div>
      <Modal
        isShowing={isModalShowing}
        hide={hideBidModal}
        modalClassNames={styles.bidIncrementModal}
      >
        <h2 className={styles.modalHeader}>Increase your AutoOffer increment</h2>
        <p>
          Increase your offer increment from the default amount to make your AutoOffer more
          competitive.
        </p>
        {!!errorMessage && (
          <div className={commonOfferStyles.alertBoxContainer}>
            <AlertBox className={commonOfferStyles.alertBox} noDefaultMargin>
              <div>{errorMessage}</div>
            </AlertBox>
          </div>
        )}
        <input
          className={styles.bidIncrementInput}
          placeholder="Enter offer increment"
          value={bidIncrementCurrency}
          onChange={handleBidIncrementChange}
          maxLength={10}
        />

        <div className={styles.buttonField}>
          <Button type="button" onClick={hideBidModal} variant="secondary">
            Cancel
          </Button>
          <Button disabled={!!errorMessage} onClick={hideBidModal}>
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};
