import { PropertyDetailsResponseBody, PropertyStatus } from '../api/_base/generated/data-contracts';
import { EmptyColumnValue } from '../helpers/constants';
import { AreaOption, PasswordStrength } from '../types/onBoarding';

import { formatNumToCurrency } from './formatter.utils';

export const strengthCheck = (
  passWordStrength: PasswordStrength,
  password: string
): PasswordStrength => {
  passWordStrength.rules.has8Chars = password.length >= 8;
  passWordStrength.rules.hasLowerCase = /[a-z]/.test(password);
  passWordStrength.rules.hasUpperCase = /[A-Z]/.test(password);
  passWordStrength.rules.hasNumber = /[0-9]/.test(password);
  passWordStrength.rules.hasSpecialCharacter = /[^A-Za-z0-9]/.test(password);

  const fullfill =
    (passWordStrength.rules.hasLowerCase ? 1 : 0) +
    (passWordStrength.rules.hasUpperCase ? 1 : 0) +
    (passWordStrength.rules.hasNumber ? 1 : 0) +
    (passWordStrength.rules.hasSpecialCharacter ? 1 : 0);

  passWordStrength.rules.fullfil3Condition = fullfill >= 3;
  passWordStrength.isValid =
    passWordStrength.rules.has8Chars && passWordStrength.rules.fullfil3Condition;

  return passWordStrength;
};

export const passwordRules = {
  rules: {
    has8Chars: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    fullfil3Condition: false
  },
  isValid: false
};

export const getSelectedIds = (primaryValue: AreaOption[], secondaryValue: AreaOption[]) => {
  const ids: string[] = [];
  if (primaryValue.length && primaryValue[0].sfId !== undefined) ids.push(primaryValue[0].sfId);

  if (secondaryValue.length) {
    secondaryValue.forEach((val) => {
      if (val.sfId !== undefined) {
        ids.push(val.sfId);
      }
    });
  }

  return ids;
};

export const squareFeetFormattedText = (squareFeet: PropertyDetailsResponseBody['squareFeet']) => {
  if (!squareFeet) {
    return '';
  }

  if (squareFeet.indexOf(',') !== -1) {
    return squareFeet;
  }

  return Number(squareFeet).toLocaleString();
};

// Phone pattern regex is misleading here as it checks after the formatting. See the test cases.
export const phonePattern = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
export const emailPattern = /\S+@\S+\.\S+/;

export const getInitials = (usersName?: string) => {
  if (!usersName) {
    return;
  }

  const names = usersName.split(' ').filter((v) => v.length > 0);

  if (!names.length) {
    return;
  }

  if (names.length > 1) {
    return names.reduce((first, last) => {
      return first[0].toUpperCase() + last[0].toUpperCase();
    });
  }

  return names[0][0].toUpperCase();
};

export const getNthNumber = (number: number) => {
  if (number > 3 && number < 21) return `${number}th`;

  const remainder = number % 10;

  if (remainder === 1) return `${number}st`;
  if (remainder === 2) return `${number}nd`;
  if (remainder === 3) return `${number}rd`;

  return `${number}th`;
};

export const offerRankText = (number: number) => `${getNthNumber(number)} Position`;

export const formattedPropertyPrice = (property: PropertyDetailsResponseBody) => {
  if (property.propertyStatus === PropertyStatus.ACTIVE) {
    return property.price ? formatNumToCurrency(property.price) : EmptyColumnValue;
  }

  if (property.propertyStatus === PropertyStatus.CLOSED) {
    return property.opportunity?.purchasePrice
      ? formatNumToCurrency(property.opportunity.purchasePrice)
      : EmptyColumnValue;
  }

  return EmptyColumnValue;
};
