import classNames from 'classnames';
import React from 'react';

import { Exit2Icon, ExitIcon } from '../Icons';

import styles from './ModalCloseButton.module.scss';

interface ModalCloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isFixed?: boolean;
  isAbsolute?: boolean; // Hack to support various modal styles until we consolidate all Modals.
  variant?: 'circle';
  buttonClassNames?: string;
  isDrawer?: boolean;
}

export const testIds = {
  modalCloseButtonRoot: 'ModalCloseButton'
};

const CLOSE_MODAL_LABEL = 'Close Modal';

export const ModalCloseButton = ({
  isFixed,
  isAbsolute,
  variant,
  buttonClassNames,
  isDrawer,
  ...rest
}: ModalCloseButtonProps) => {
  return (
    <button
      data-testid={testIds.modalCloseButtonRoot}
      aria-label={CLOSE_MODAL_LABEL}
      className={classNames([
        styles.modalCloseButton,
        {
          [styles.fixedButton]: isFixed,
          [styles.absoluteButton]: isAbsolute,
          [styles.circleButton]: variant === 'circle'
        },
        buttonClassNames
      ])}
      type="button"
      {...rest}
    >
      {isDrawer ? <Exit2Icon aria-hidden /> : <ExitIcon aria-hidden />}
    </button>
  );
};

ModalCloseButton.defaultProps = {
  isFixed: true,
  variant: 'default'
};
