import classNames from 'classnames';
import _ from 'lodash';
import React, { MouseEventHandler, useState } from 'react';

import styles from './Card.module.scss';

export const testIds = {
  root: 'Card',
  radioButton: 'CardRadioButton'
};

type CardProps = React.PropsWithChildren<{
  'data-testid'?: string;
  className?: string;
  selected?: boolean;
  noPadding?: boolean;
  disabled?: boolean;
  onSelection?: MouseEventHandler;
  // Adds a radio button to the card, and calls the provided callback function on click.
}>;

export const Card: React.FC<CardProps> = ({
  children,
  className,
  selected = false,
  noPadding = false,
  disabled = false,
  'data-testid': dataTestId,
  onSelection
}: CardProps) => {
  const [isSelected, setIsSelected] = useState(selected);

  return (
    <div
      className={classNames(
        className,
        styles.card,
        noPadding && styles.noPadding,
        disabled && styles.disabled
      )}
      data-testid={dataTestId || testIds.root}
      onClick={(e) => {
        setIsSelected(!isSelected);

        if (!disabled && onSelection) {
          onSelection(e);
        }
      }}
    >
      {onSelection ? (
        <div className={classNames(styles.inputWrapper)}>
          <input
            className={classNames('selection')}
            data-testid={testIds.radioButton}
            type="radio"
            disabled={disabled}
            checked={selected}
            onChange={_.noop}
          />
        </div>
      ) : (
        ''
      )}
      <div className={classNames(styles.contentWrapper)}>{children}</div>
    </div>
  );
};
