import classNames from 'classnames';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { getMessageFromError, isApiErrorWithMessage } from '../../api/user';
import { getUserInfo } from '../../api/user/getUserInfo';
import { verifyNewPhoneCode } from '../../api/user-account/accountSettingsApi';
import { AlertBox } from '../../components/AlertBox';
import { ConfirmationCodeInput } from '../../components/ConfirmationCodeInput/ConfirmationCodeInput';
import { Modal } from '../../components/Modal';
import { SubmitButton } from '../../components/SubmitButton';
import { useConfirmationCode } from '../../hooks/store/misc';
import { useModalByName } from '../../hooks/useModalByName';
import { captureException } from '../../logging';
import { ModalHeader } from '../ModalHeader';

import styles from './ConfirmationCodeInputModal.module.scss';

import { CONFIRMATION_CODE_PHONE_INPUT_MODAL_NAME } from '.';

const confirmationCodeInputModalTestIds = {
  alertBox: 'alertBox'
};

export const ConfirmationCodePhoneInputModal = () => {
  const [isConfirmationCodeInvalid, setIsConfirmationCodeInvalid] = useState(true);
  const [password, setPassword] = useState('');
  const [isOpen, , close, options] = useModalByName(CONFIRMATION_CODE_PHONE_INPUT_MODAL_NAME);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { confirmationCode } = useConfirmationCode();

  const handleSubmitConfirmationCode = () => {
    if (confirmationCode && password) {
      setIsSubmitting(true);
      verifyNewPhoneCode({ code: confirmationCode, password })
        .then(() => {
          getUserInfo().then(() => {
            setIsSubmitting(false);
            if (options?.onComplete) options.onComplete();
            toast.success('Phone Number Successfully Changed');
            close();
          });
        })
        .catch((error: unknown) => {
          setIsSubmitting(false);

          captureException(error);

          if (isApiErrorWithMessage(error)) {
            setErrorMessage(getMessageFromError(error));
          } else {
            setErrorMessage('Invalid verification code provided, please try again.');
          }
        });
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.currentTarget.value;
    setPassword(newPassword);
  };

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={styles.modal}>
      <ModalHeader
        onClickIcon={close}
        headerClassNames={styles.header}
        headerText="Please verify your phone"
      />
      <ConfirmationCodeInput codeLength={6} disableCallback={setIsConfirmationCodeInvalid} />
      <input
        type="password"
        className={classNames('inputField')}
        placeholder="Enter Password"
        value={password}
        onChange={handlePasswordChange}
      />
      {errorMessage && (
        <AlertBox data-testid={confirmationCodeInputModalTestIds.alertBox}>{errorMessage}</AlertBox>
      )}
      <SubmitButton
        disabled={isConfirmationCodeInvalid || !password}
        onClick={handleSubmitConfirmationCode}
        loading={isSubmitting}
      >
        Submit
      </SubmitButton>
    </Modal>
  );
};
