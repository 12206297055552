import classNames from 'classnames';
import React, { ElementRef, forwardRef, InputHTMLAttributes } from 'react';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode; // Is required for accessibility reasons
  hasError?: boolean; // Add Sundae red border around Checkbox
}

export const testIds = {
  root: 'Checkbox',
  input: 'CheckboxInput',
  label: 'CheckboxLabel'
};

const DEFAULT_TAG = 'input';

export const Checkbox = forwardRef<ElementRef<typeof DEFAULT_TAG>, CheckboxProps>(
  ({ label, hasError, className, ...rest }, ref) => {
    return (
      <>
        <label data-testid={testIds.root} className={classNames(styles.checkbox, className)}>
          <input
            className={classNames({ [styles.emphasized]: hasError })}
            data-testid={testIds.input}
            type="checkbox"
            ref={ref}
            {...rest}
          />
          <span data-testid={testIds.label} className={classNames(styles.label)}>
            {label}
          </span>
        </label>
      </>
    );
  }
);
