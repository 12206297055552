import classNames from 'classnames';
import React from 'react';

import styles from './InlineNotification.module.scss';

export const testIds = {
  root: 'InlineNotification'
};

type InlineNotificationProps = React.PropsWithChildren<{
  'data-testid'?: string;
  className?: string;
}>;

export const InlineNotification: React.FC<InlineNotificationProps> = ({
  'data-testid': dataTestId,
  children,
  className
}: InlineNotificationProps) => {
  return (
    <div
      className={classNames(styles.inlineNotification, className)}
      data-testid={dataTestId || testIds.root}
    >
      {children}
    </div>
  );
};
