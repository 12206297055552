import classNames from 'classnames';
import React from 'react';

import buttonStyles from '../common/buttons.module.scss';
import { ExitIcon } from '../components/Icons';

import styles from './Modals.module.scss';

interface ModalHeaderProps {
  onClickIcon: () => void;
  headerText?: string;
  closeIconClassNames?: string;
  headerClassNames?: string;
}

export const testIds = {
  closeModalButton: 'closeModalButton'
};

export const ModalHeader = ({
  onClickIcon,
  headerText,
  closeIconClassNames,
  headerClassNames
}: ModalHeaderProps) => {
  return (
    <div className={classNames(styles.modalHeader, headerClassNames)}>
      {headerText}
      <ExitIcon
        className={classNames(buttonStyles.closeIcon, closeIconClassNames)}
        onClick={onClickIcon}
        title="Close"
        data-testid={testIds.closeModalButton}
      />
    </div>
  );
};
